import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Breadcrumb from "../common/Breadcrumb";
import "react-image-lightbox/style.css";
import MenuBar from '../../blocks/MenuBar';

const dataList = [
    { colume1: '• 0.1 กรัม', colume2: '• 1 บาท' },
    { colume1: '• 1 กรัม', colume2: '• 2 บาท' },
    { colume1: '• 1 เฟื้อง ', colume2: '• 5 บาท' },
    { colume1: '• 1 สลึง', colume2: '• 10 บาท' },
    { colume1: '• 2 สลึง', colume2: '• 20 บาท' },
    { colume1: ' ', colume2: '• 50 บาท' },
    { colume1: '• ทองคำแท่ง 96.5% หนึ่งบาททอง เท่ากับ 15.244 กรัม', },
    { colume1: '• ทองคำรูปพรรณ 96.5% หนึ่งบาททอง เท่ากับ 15.16 กรัม ', }
];


class Gold965 extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='ทองคำแท่ง 96.5%' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />
                <Breadcrumb title={'ทองคำแท่ง 96.5%'} bgimage={'/assets/images/breadcrumb/breadcrumb-gold965.webp'}  description={'ทองคำแท่ง 96.5% ที่ออกแบบอย่างมาตรฐานสากล เหมาะกับการเก็บรักษาเพื่อเก็งกำไร หรือมอบเป็นของขวัญอันล้ำค่าในโอกาสพิเศษ เรียบหรู โดดเด่น คุณภาพพรีเมี่ยม ห้างทองเยาวราช1 ยโสธร พร้อมบริการด้วยความใส่ใจ'}/>

                <div className="product-area">
                    <div className="gold ptb--50">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                                    <div className="img">
                                        <img src={`/assets/images/product/gold965/gold965-01.webp`} alt="ทองคำแท่ง 96.5%" />
                                    </div>
                                    <div className="section-title mb--20 mt--20">
                                        <h3 className="title">ทองคำ 96.5% มาตรฐานเยาวราช</h3>
                                        <p className="description">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ทองคำมาตรฐานที่สมาคมค้าทองคำ และร้านทองทั่วประเทศให้การยอมรับ ห้างทองเยาวราช1 ยโสธร เป็นตัวแทน ซื้อขายทองคำแท่งของ บ.ชายน์นิ่ง โกลด์ บูลเลี่ยน จำกัด ซึ่งเป็นหนึ่งในสมาชิกของสมาคมค้าทองคำ ซึ่งเป็นบริษัทนำเข้าและส่งออกทองคำแท่งรายใหญ่ของประเทศ ห้างทองเยาวราช 1 ยโสธร รับซื้อและขายทองคำแท่งทั้ง 96.5 % และ 99.99% ทุกแบบและทุกยี่ห้อ โดยมีน้ำหนักทองคำแท่งดังนี้</p>
                                    </div>

                                    <div>
                                        <table className="dataTable">
                                            <tbody>
                                                {dataList.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="col-6">{item.colume1}</td>
                                                        <td className="col-6">{item.colume2}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="image-row">
                                        <div className="img">
                                            <img src={`/assets/images/product/gold965/gold965-02.webp`} alt="ทองคำแท่ง 96.5%" />
                                            <img src={`/assets/images/product/gold965/gold965-03.webp`} alt="ทองคำแท่ง 96.5%" />
                                            <img src={`/assets/images/product/gold965/gold965-04.webp`} alt="ทองคำแท่ง 96.5%" />
                                            <img src={`/assets/images/product/gold965/gold965-05.webp`} alt="ทองคำแท่ง 96.5%" />
                                            <img src={`/assets/images/product/gold965/gold965-06.webp`} alt="ทองคำแท่ง 96.5%" />
                                            <img src={`/assets/images/product/gold965/gold965-07.webp`} alt="ทองคำแท่ง 96.5%" />
                                            <img src={`/assets/images/product/gold965/gold965-08.webp`} alt="ทองคำแท่ง 96.5%" />
                                            <img src={`/assets/images/product/gold965/gold965-09.webp`} alt="ทองคำแท่ง 96.5%" />
                                            <img src={`/assets/images/product/gold965/gold965-10.webp`} alt="ทองคำแท่ง 96.5%" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt--40">
                                    <MenuBar />
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                {/* Start Back To Top */}
                < div className="backto-top" >
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div >
                {/* End Back To Top */}
                <Footer />
            </React.Fragment>
        )
    }
}
export default Gold965;