import React, { Component } from "react";
// import { Link } from "react-router-dom";

class Breadcrumb extends Component {
    render() {
        const { title, description, bgimage } = this.props;
        const breadcrumbStyle = {
            backgroundImage: `url(${bgimage})`,
        };

        return (
            <React.Fragment>
                <div className="breadcrumb-area ptb--50 bg_image mb--200" style={breadcrumbStyle}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-frame">
                                    <div className="breadcrumb-inner">
                                        <h2 className="title">{title}</h2>
                                        <p className="title">{description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Breadcrumb;

