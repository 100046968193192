
export const Necklace = [
  { 
    id: 1, 
    name: 'สร้อยคอลูกเดือย', 
    description: 'น้ำหนัก 10 บาท ยาว 33.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-03-1.webp',
      '/assets/images/product/necklace/necklace-03-2.webp',
      '/assets/images/product/necklace/necklace-03-3.webp'
    ]
  },
  { 
    id: 2, 
    name: 'สร้อยคอซุปเปอร์ล้อแม็ค', 
    description: 'น้ำหนัก 10 บาท ยาว 33.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
     
    additionalImages: [
      '/assets/images/product/necklace/necklace-02-1.webp',
      '/assets/images/product/necklace/necklace-02-2.webp',
      '/assets/images/product/necklace/necklace-02-3.webp'
    ]
  },
  { 
    id: 3, 
    name: 'สร้อยคอสรวิศ', 
    description: 'น้ำหนัก 10 บาท ยาว 33.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    imgSrc: '/assets/images/product/necklace/necklace-01-1.webp',
    additionalImages: [
      '/assets/images/product/necklace/necklace-01-1.webp',
      '/assets/images/product/necklace/necklace-01-2.webp',
      '/assets/images/product/necklace/necklace-01-3.webp'
    ]
  },
  { 
    id: 4, 
    name: 'สร้อยคอระย้าคีรีมันตรา', 
    description: 'น้ำหนัก 5 บาท ยาว 28 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    imgSrc: '/assets/images/product/necklace/necklace-07-1.webp',
    additionalImages: [
      '/assets/images/product/necklace/necklace-07-1.webp',
      '/assets/images/product/necklace/necklace-07-2.webp',
      '/assets/images/product/necklace/necklace-07-3.webp'
    ]
  },
  { 
    id: 5, 
    name: 'สร้อยคอโซ่โปร่ง', 
    description: 'น้ำหนัก 5 บาท ยาว 32 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-06-1.webp',
      '/assets/images/product/necklace/necklace-06-2.webp',
      '/assets/images/product/necklace/necklace-06-3.webp'
    ]
  },

  { 
    id: 6, 
    name: 'สร้อยคอเบนซ์ลงยา', 
    description: 'น้ำหนัก 5 บาท ยาว 30 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-05-1.webp',
      '/assets/images/product/necklace/necklace-05-2.webp',
      '/assets/images/product/necklace/necklace-05-3.webp'
    ]
  },
  { 
    id: 7, 
    name: 'สร้อยคอเบนซ์กลม', 
    description: 'น้ำหนัก 5 บาท ยาว 34.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-04-1.webp',
      '/assets/images/product/necklace/necklace-04-2.webp',
      '/assets/images/product/necklace/necklace-04-3.webp'
    ]
  },
  { 
    id: 8, 
    name: 'สร้อยคอห่วงบิดโปร่ง', 
    description: 'น้ำหนัก 3 บาท ยาว 22-24 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-10-1.webp',
      '/assets/images/product/necklace/necklace-10-2.webp',
      '/assets/images/product/necklace/necklace-10-3.webp'
    ]
  },
  { 
    id: 9, 
    name: 'สร้อยคอเบนซ์กลม ห้อยจี้หัวใจ-ถุงทอง', 
    description: 'น้ำหนัก 3 บาท ยาว 24 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-11-1.webp',
      '/assets/images/product/necklace/necklace-11-2.webp',
      '/assets/images/product/necklace/necklace-11-3.webp'
    ]
  },
  { 
    id: 10, 
    name: 'สร้อยคออาซาร์', 
    description: 'น้ำหนัก 3 บาท ยาว 34-36 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-09-1.webp',
      '/assets/images/product/necklace/necklace-09-2.webp',
      '/assets/images/product/necklace/necklace-09-3.webp'
    ]
  },
  { 
    id: 11, 
    name: 'สร้อยคอเบนซ์กลมคั่นโอ่ง', 
    description: 'น้ำหนัก 3 บาท ยาว 29 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-08-1.webp',
      '/assets/images/product/necklace/necklace-08-2.webp',
      '/assets/images/product/necklace/necklace-08-3.webp'
    ]
  },
  { 
    id: 12, 
    name: 'สร้อยคอระย้าฝรั่งเศสสายคู่ CC', 
    description: 'น้ำหนัก 2 บาท ยาว 25 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-16-1.webp',
      '/assets/images/product/necklace/necklace-16-2.webp',
      '/assets/images/product/necklace/necklace-16-3.webp'
    ]
  },
  { 
    id: 13, 
    name: 'สร้อยคอโซ่ฝรั่ง', 
    description: 'น้ำหนัก 2 บาท ยาว 34 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-14-1.webp',
      '/assets/images/product/necklace/necklace-14-2.webp',
      '/assets/images/product/necklace/necklace-14-3.webp'
    ]
  },
  { 
    id: 14, 
    name: 'สร้อยคอทาโร่', 
    description: 'น้ำหนัก 2 บาท ยาว 32 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-13-1.webp',
      '/assets/images/product/necklace/necklace-13-2.webp',
      '/assets/images/product/necklace/necklace-13-3.webp'
    ]
  },
  { 
    id: 15, 
    name: 'สร้อยคอเบนซ์', 
    description: 'น้ำหนัก 2 บาท ยาว 28 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-12-1.webp',
      '/assets/images/product/necklace/necklace-12-2.webp',
      '/assets/images/product/necklace/necklace-12-3.webp'
    ]
  },
  { 
    id: 16, 
    name: 'สร้อยคอเบนซ์ห้อยจี้ หัวใจ', 
    description: 'น้ำหนัก 1 บาท ยาว 24.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-20-1.webp',
      '/assets/images/product/necklace/necklace-20-2.webp',
      '/assets/images/product/necklace/necklace-20-3.webp'
    ]
  },
  { 
    id: 17, 
    name: 'สร้อยคอเบนซ์มัดข้างลงยา', 
    description: 'น้ำหนัก 1 บาท ยาว 24.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-19-1.webp',
      '/assets/images/product/necklace/necklace-19-2.webp',
      '/assets/images/product/necklace/necklace-19-3.webp'
    ]
  },
  { 
    id: 18, 
    name: 'สร้อยคอเกลียวฟิชโช่มัดข้าง', 
    description: 'น้ำหนัก 1 บาท ยาว 30.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-18-1.webp',
      '/assets/images/product/necklace/necklace-18-2.webp',
      '/assets/images/product/necklace/necklace-18-3.webp'
    ]
  },
  { 
    id: 19, 
    name: 'สร้อยคอทาโร่สลับท่อน', 
    description: 'น้ำหนัก 1 บาท ยาว 29.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-17-1.webp',
      '/assets/images/product/necklace/necklace-17-2.webp',
      '/assets/images/product/necklace/necklace-17-3.webp'
    ]
  },
  { 
    id: 20, 
    name: 'สร้อยคอเบนซ์ห้อยจี้ กระต่าย', 
    description: 'น้ำหนัก 2 สลึง ยาว 25.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-24-1.webp',
      '/assets/images/product/necklace/necklace-24-2.webp',
      '/assets/images/product/necklace/necklace-24-3.webp'
    ]
  },
  { 
    id: 21, 
    name: 'สร้อยคอยินตัน 2 กษัตริย์', 
    description: 'น้ำหนัก 2 สลึง ยาว 21 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-23-1.webp',
      '/assets/images/product/necklace/necklace-23-2.webp',
      '/assets/images/product/necklace/necklace-23-3.webp'
    ]
  },
  { 
    id: 22, 
    name: 'สร้อยคอเบนซ์คั่นปี่เซียะ', 
    description: 'น้ำหนัก 2 สลึง ยาว 23.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-22-1.webp',
      '/assets/images/product/necklace/necklace-22-2.webp',
      '/assets/images/product/necklace/necklace-22-3.webp'
    ]
  },
  { 
    id: 23, 
    name: 'สร้อยคอผ่าหวายโปร่ง', 
    description: 'น้ำหนัก 2 สลึง ยาว 30.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-21-1.webp',
      '/assets/images/product/necklace/necklace-21-2.webp',
      '/assets/images/product/necklace/necklace-21-3.webp'
    ]
  },
  { 
    id: 24, 
    name: 'สร้อยคอเซเลปซีลีน YSL', 
    description: 'น้ำหนัก 1 สลึง ยาว 22 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-28-1.webp',
      '/assets/images/product/necklace/necklace-28-2.webp',
      '/assets/images/product/necklace/necklace-28-3.webp'
    ]
  },
  { 
    id: 25, 
    name: 'สร้อยคอเบนซ์มัดข้าง', 
    description: 'น้ำหนัก 1 สลึง ยาว 24 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-27-1.webp',
      '/assets/images/product/necklace/necklace-27-2.webp',
      '/assets/images/product/necklace/necklace-27-3.webp'
    ]
  },
  { 
    id: 26, 
    name: 'สร้อยคอซีโอ', 
    description: 'น้ำหนัก 1 สลึง ยาว 25.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-26-1.webp',
      '/assets/images/product/necklace/necklace-26-2.webp',
      '/assets/images/product/necklace/necklace-26-3.webp'
    ]
  },
  { 
    id: 27, 
    name: 'สร้อยคอผ่าหวายทรงเครื่อง', 
    description: 'น้ำหนัก 1 สลึง ยาว 24.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/necklace/necklace-25-1.webp',
      '/assets/images/product/necklace/necklace-25-2.webp',
      '/assets/images/product/necklace/necklace-25-3.webp'
    ]
  }
];


export const Ring = [
  { 
    id: 101, 
    name: 'แหวนสามกษัตริย์', 
    description: 'น้ำหนัก 2 บาท เบอร์ 59', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-01-1.webp',
      '/assets/images/product/ring/ring-01-2.webp'
    ]
  },
  { 
    id: 102, 
    name: 'แหวนมังกรฉลุ', 
    description: 'น้ำหนัก 2 บาท เบอร์ 71', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
     
    additionalImages: [
      '/assets/images/product/ring/ring-02-1.webp',
      '/assets/images/product/ring/ring-02-2.webp'
    ]
  },
  { 
    id: 103, 
    name: 'แหวนโปร่งนิยม', 
    description: 'น้ำหนัก 1 บาท เบอร์ 60', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-03-1.webp',
      '/assets/images/product/ring/ring-03-2.webp'
    ]
  },
  { 
    id: 104, 
    name: 'แหวนแกะลาย', 
    description: 'น้ำหนัก 1 บาท เบอร์ 62', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    imgSrc: '/assets/images/product/ring/ring-07-1.webp',
    additionalImages: [
      '/assets/images/product/ring/ring-04-1.webp',
      '/assets/images/product/ring/ring-04-2.webp'
    ]
  },
  { 
    id: 105, 
    name: 'แหวนคีรีมันตรา', 
    description: 'น้ำหนัก 1 บาท เบอร์ 59', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-05-1.webp',
      '/assets/images/product/ring/ring-05-2.webp'
    ]
  },

  { 
    id: 106, 
    name: 'แหวนโปร่งมังกร', 
    description: 'น้ำหนัก 1 บาท เบอร์ 68', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-06-1.webp',
      '/assets/images/product/ring/ring-06-2.webp'
    ]
  },
  { 
    id: 107, 
    name: 'แหวนมังกรฉลุ', 
    description: 'น้ำหนัก 1 บาท เบอร์ 69', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-07-1.webp',
      '/assets/images/product/ring/ring-07-2.webp'
    ]
  },
  { 
    id: 108, 
    name: 'แหวน CC 2 กษัตริย์', 
    description: 'น้ำหนัก 2 สลึง เบอร์ 58', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-08-1.webp',
      '/assets/images/product/ring/ring-08-2.webp'
    ]
  },
  { 
    id: 109, 
    name: 'แหวนโปร่งลายจีน', 
    description: 'น้ำหนัก 2 สลึง เบอร์ 60', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-09-1.webp',
      '/assets/images/product/ring/ring-09-2.webp'
    ]
  },
  { 
    id: 110, 
    name: 'แหวนโปร่งนิยม', 
    description: 'น้ำหนัก 2 สลึง เบอร์ 57', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-10-1.webp',
      '/assets/images/product/ring/ring-10-2.webp'
    ]
  },
  { 
    id: 111, 
    name: 'แหวนโปร่งหัวใจ', 
    description: 'น้ำหนัก 2 สลึง เบอร์ 54', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-11-1.webp',
      '/assets/images/product/ring/ring-11-2.webp'
    ]
  },
  { 
    id: 112, 
    name: 'แหวนโลมาคู่', 
    description: 'น้ำหนัก 2 สลึง เบอร์ 62', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-12-1.webp',
      '/assets/images/product/ring/ring-12-2.webp'
    ]
  },
  { 
    id: 113, 
    name: 'แหวนใบมะกอกเรียกทรัพย์', 
    description: 'น้ำหนัก 2 สลึง เบอร์ 62', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-13-1.webp',
      '/assets/images/product/ring/ring-13-2.webp'
    ]
  },
  { 
    id: 114, 
    name: 'แหวนโปร่งจิกเพชร', 
    description: 'น้ำหนัก 1 สลึง เบอร์ 56', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-14-1.webp',
      '/assets/images/product/ring/ring-14-2.webp'
    ]
  },
  { 
    id: 115, 
    name: 'แหวนแกะลาย', 
    description: 'น้ำหนัก 1 สลึง เบอร์ 59', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-15-1.webp',
      '/assets/images/product/ring/ring-15-2.webp'
    ]
  },
  { 
    id: 116, 
    name: 'แหวนโปร่งลายตาราง', 
    description: 'น้ำหนัก 1 สลึง เบอร์ 55', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-16-1.webp',
      '/assets/images/product/ring/ring-16-2.webp'
    ]
  },
  { 
    id: 117, 
    name: 'แหวนแกะลาย', 
    description: 'น้ำหนัก 1 สลึง เบอร์ 59', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-17-1.webp',
      '/assets/images/product/ring/ring-17-2.webp'
    ]
  },
  { 
    id: 118, 
    name: 'แหวนแกรนด์ไดมอนด์', 
    description: 'น้ำหนัก 1 สลึง เบอร์ 54', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-18-1.webp',
      '/assets/images/product/ring/ring-18-2.webp'
    ]
  },
  { 
    id: 119, 
    name: 'แหวนหัวใจ', 
    description: 'น้ำหนัก 1 สลึง เบอร์ 58', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-19-1.webp',
      '/assets/images/product/ring/ring-19-2.webp'
    ]
  },
  { 
    id: 120, 
    name: 'แหวนซีลีนลงยา', 
    description: 'น้ำหนัก ครึ่งสลึง เบอร์ 55', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-20-1.webp',
      '/assets/images/product/ring/ring-20-2.webp'
    ]
  },
  { 
    id: 121, 
    name: 'แหวนโบว์', 
    description: 'น้ำหนัก ครึ่งสลึง เบอร์ 55', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-21-1.webp',
      '/assets/images/product/ring/ring-21-2.webp'
    ]
  },
  { 
    id: 122, 
    name: 'แหวนแกะลาย', 
    description: 'น้ำหนัก ครึ่งสลึง เบอร์ 57', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-22-1.webp',
      '/assets/images/product/ring/ring-22-2.webp'
    ]
  },
  { 
    id: 123, 
    name: 'แหวนแกะลายจีน', 
    description: 'น้ำหนัก ครึ่งสลึง เบอร์ 62', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-23-1.webp',
      '/assets/images/product/ring/ring-23-2.webp'
    ]
  },
  { 
    id: 124, 
    name: 'แหวนโปร่งลายเพชร', 
    description: 'น้ำหนัก ครึ่งสลึง เบอร์ 60', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-24-1.webp',
      '/assets/images/product/ring/ring-24-2.webp'
    ]
  },
  { 
    id: 125, 
    name: 'แหวนโปร่งจิกเพชร', 
    description: 'น้ำหนัก ครึ่งสลึง เบอร์ 56', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-25-1.webp',
      '/assets/images/product/ring/ring-25-2.webp'
    ]
  },
  { 
    id: 126, 
    name: 'แหวนแกะลาย', 
    description: 'น้ำหนัก 1 กรัม เบอร์ 57', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-26-1.webp',
      '/assets/images/product/ring/ring-26-2.webp'
    ]
  },
  { 
    id: 127, 
    name: 'แหวนโปร่งจิกเพชร', 
    description: 'น้ำหนัก 1 กรัม เบอร์ 54', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-27-1.webp',
      '/assets/images/product/ring/ring-27-2.webp'
    ]
  },
  { 
    id: 128, 
    name: 'แหวน infinity', 
    description: 'น้ำหนัก 1 กรัม เบอร์ 58', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/ring/ring-28-1.webp',
      '/assets/images/product/ring/ring-28-2.webp'
    ]
  }
];


export const Bangle = [
  { 
    id: 201, 
    name: 'กำไลพิกุล', 
    description: 'น้ำหนัก 5 บาท เส้นผ่าศูนย์กลาง 5.2 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-01-1.webp',
      '/assets/images/product/bangle/bangle-01-2.webp'
    ]
  },
  { 
    id: 202, 
    name: 'กำไลชาแนล', 
    description: 'น้ำหนัก 3 บาท เส้นผ่าศูนย์กลาง 5.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    imgSrc: '/assets/images/product/bangle/bangle-01-1.webp',
    additionalImages: [
      '/assets/images/product/bangle/bangle-02-1.webp',
      '/assets/images/product/bangle/bangle-02-2.webp'
    ]
  },
  { 
    id: 203, 
    name: 'กำไลคอนโด 2 กษัตริย์', 
    description: 'น้ำหนัก 3 บาท เส้นผ่าศูนย์กลาง 5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    imgSrc: '/assets/images/product/bangle/bangle-07-1.webp',
    additionalImages: [
      '/assets/images/product/bangle/bangle-03-1.webp',
      '/assets/images/product/bangle/bangle-03-2.webp'
    ]
  },
  { 
    id: 204, 
    name: 'กำไลสปริง', 
    description: 'น้ำหนัก 3 บาท เส้นผ่าศูนย์กลาง 5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-04-1.webp',
      '/assets/images/product/bangle/bangle-04-2.webp'
    ]
  },

  { 
    id: 205, 
    name: 'กำไลคาเทียร์', 
    description: 'น้ำหนัก 3 บาท เส้นผ่าศูนย์กลาง 6 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-05-1.webp',
      '/assets/images/product/bangle/bangle-05-2.webp'
    ]
  },
  { 
    id: 206, 
    name: 'กำไลปลาทองลงยา', 
    description: 'น้ำหนัก 38.45 กรัม เส้นผ่าศูนย์กลาง 5.3 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-06-1.webp',
      '/assets/images/product/bangle/bangle-06-2.webp'
    ]
  },
  { 
    id: 207, 
    name: 'กำไล hermes ลงยา', 
    description: 'น้ำหนัก 2 บาท เส้นผ่าศูนย์กลาง 5 ซม.',  
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-07-1.webp',
      '/assets/images/product/bangle/bangle-07-2.webp'
    ]
  },
  { 
    id: 208, 
    name: 'กำไลแพหลังเต่า', 
    description: 'น้ำหนัก 2 บาท เส้นผ่าศูนย์กลาง 5.5 ซม.',  
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-08-1.webp',
      '/assets/images/product/bangle/bangle-08-2.webp'
    ]
  },
  { 
    id: 209,  
    name: 'กำไลใบไม้ตัดลาย', 
    description: 'น้ำหนัก 2 บาท เส้นผ่าศูนย์กลาง 5.8 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-09-1.webp',
      '/assets/images/product/bangle/bangle-09-2.webp'
    ]
  },
  { 
    id: 210,  
    name: 'กำไลสานทรัพย์', 
    description: 'น้ำหนัก 2 บาท เส้นผ่าศูนย์กลาง 5.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-10-1.webp',
      '/assets/images/product/bangle/bangle-10-2.webp'
    ]
  },
  { 
    id: 211, 
    name: 'กำไล CD 2 กษัตริย์', 
    description: 'น้ำหนัก 2 บาท เส้นผ่าศูนย์กลาง 5.2 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-11-1.webp',
      '/assets/images/product/bangle/bangle-11-2.webp'
    ]
  },
  { 
    id: 212,  
    name: 'กำไลเหลี่ยมเพชรสามกษัตริย์', 
    description: 'น้ำหนัก 2 บาท เส้นผ่าศูนย์กลาง 5.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-12-1.webp',
      '/assets/images/product/bangle/bangle-12-2.webp'
    ]
  },
  { 
    id: 213,  
    name: 'กำไลแกะลายสามกษัตริย์', 
    description: 'น้ำหนัก 1 บาท เส้นผ่าศูนย์กลาง 5.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-13-1.webp',
      '/assets/images/product/bangle/bangle-13-2.webp'
    ]
  },
  { 
    id: 214, 
    name: 'กำไลลงยา LV', 
    description: 'น้ำหนัก 1 บาท เส้นผ่าศูนย์กลาง 5.2 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-14-1.webp',
      '/assets/images/product/bangle/bangle-14-2.webp'
    ]
  },
  { 
    id: 215,  
    name: 'กำไล Tiffany', 
    description: 'น้ำหนัก 1 บาท เส้นผ่าศูนย์กลาง 5.3 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-15-1.webp',
      '/assets/images/product/bangle/bangle-15-2.webp'
    ]
  },
  { 
    id: 216,  
    name: 'กำไลดอกคามิเลีย', 
    description: 'น้ำหนัก 1 บาท เส้นผ่าศูนย์กลาง 5.3 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-16-1.webp',
      '/assets/images/product/bangle/bangle-16-2.webp'
    ]
  },
  { 
    id: 217, 
    name: 'กำไล versace 2 กษัตริย์', 
    description: 'น้ำหนัก 1 บาท เส้นผ่าศูนย์กลาง 5.8 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-17-1.webp',
      '/assets/images/product/bangle/bangle-17-2.webp'
    ]
  },
  { 
    id: 218, 
    name: 'กำไลเกลี้ยง', 
    description: 'น้ำหนัก 1 บาท เส้นผ่าศูนย์กลาง 5.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-18-1.webp',
      '/assets/images/product/bangle/bangle-18-2.webp'
    ]
  },
  { 
    id: 219,  
    name: 'กำไล CD', 
    description: 'น้ำหนัก 1 บาท เส้นผ่าศูนย์กลาง 5.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-19-1.webp',
      '/assets/images/product/bangle/bangle-19-2.webp'
    ]
  },
  { 
    id: 220, 
    name: 'กำไลคาเทียร์ตะปูฝังเพชร', 
    description: 'น้ำหนัก 1 บาท เส้นผ่าศูนย์กลาง 5.6 ซม.',  
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-20-1.webp',
      '/assets/images/product/bangle/bangle-20-2.webp'
    ]
  },
  { 
    id: 221,  
    name: 'กำไลซีโอ 2 กษัตริย์', 
    description: 'น้ำหนัก 1 บาท เส้นผ่าศูนย์กลาง 5.2 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-21-1.webp',
      '/assets/images/product/bangle/bangle-21-2.webp'
    ]
  },
  { 
    id: 222, 
    name: 'กำไลเถาวัลย์', 
    description: 'น้ำหนัก 2 สลึง เส้นผ่าศูนย์กลาง 6 ซม.',  
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-22-1.webp',
      '/assets/images/product/bangle/bangle-22-2.webp'
    ]
  },
  { 
    id: 223,  
    name: 'กำไลหัวบัว ลงยา', 
    description: 'น้ำหนัก 2 สลึง เส้นผ่าศูนย์กลาง 5.2 ซม.',  
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-23-1.webp',
      '/assets/images/product/bangle/bangle-23-2.webp'
    ]
  },
  { 
    id: 224,  
    name: 'กำไลพญานาคบันดาลโชค', 
    description: 'น้ำหนัก 2 สลึง เส้นผ่าศูนย์กลาง 5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-24-1.webp',
      '/assets/images/product/bangle/bangle-24-2.webp'
    ]
  },
  { 
    id: 225,  
    name: 'กำไลใบเมเปิ้ล', 
    description: 'น้ำหนัก 2 สลึง เส้นผ่าศูนย์กลาง 5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-25-1.webp',
      '/assets/images/product/bangle/bangle-25-2.webp'
    ]
  },
  { 
    id: 226,  
    name: 'กำไลคาเทียร์เลิฟ 2 กษัตริย์', 
    description: 'น้ำหนัก 2 สลึง เส้นผ่าศูนย์กลาง 5.2 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-26-1.webp',
      '/assets/images/product/bangle/bangle-26-2.webp'
    ]
  },
  { 
    id: 227,  
    name: 'กำไล CD', 
    description: 'น้ำหนัก 2 สลึง เส้นผ่าศูนย์กลาง 5.2 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bangle/bangle-27-1.webp',
      '/assets/images/product/bangle/bangle-27-2.webp'
    ]
  },
];


export const Bracelet = [
  { 
    id: 301, 
    name: 'สร้อยข้อมือเบนซ์แพมังกร', 
    description: 'น้ำหนัก 10 บาท ยาว 18.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-01-1.webp',
      '/assets/images/product/bracelet/bracelet-01-2.webp'
    ]
  },
  { 
    id: 302, 
    name: 'สร้อยข้อมือเบนซ์สายคู่ ห้อยดาวใจ', 
    description: 'น้ำหนัก 10 บาท ยาว 21 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-02-1.webp',
      '/assets/images/product/bracelet/bracelet-02-2.webp'
    ]
  },
  { 
    id: 303, 
    name: 'สร้อยข้อมือคีรีมันตรา', 
    description: 'น้ำหนัก 5 บาท ยาว 17-20.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-03-1.webp',
      '/assets/images/product/bracelet/bracelet-03-2.webp'
    ]
  },
  { 
    id: 304, 
    name: 'สร้อยข้อมือลายโรมิโอ', 
    description: 'น้ำหนัก 5 บาท ยาว 18.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-04-1.webp',
      '/assets/images/product/bracelet/bracelet-04-2.webp'
    ]
  },

  { 
    id: 305, 
    name: 'สร้อยข้อมือลายเลท', 
    description: 'น้ำหนัก 5 บาท ยาว 20.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-05-1.webp',
      '/assets/images/product/bracelet/bracelet-05-2.webp'
    ]
  },
  { 
    id: 306, 
    name: 'สร้อยข้อมือเบนซ์แพ ห้อยดาวใจ', 
    description: 'น้ำหนัก 5 บาท ยาว 19.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-06-1.webp',
      '/assets/images/product/bracelet/bracelet-06-2.webp'
    ]
  },
  { 
    id: 307, 
    name: 'สร้อยข้อมือแวร์ซาย LV', 
    description: 'น้ำหนัก 3 บาท ยาว 16 ซม.',  
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-07-1.webp',
      '/assets/images/product/bracelet/bracelet-07-2.webp'
    ]
  },
  { 
    id: 308, 
    name: 'สร้อยข้อมือเกล็ดมัจฉาคั่นตุ้ม', 
    description: 'น้ำหนัก 3 บาท ยาว 18.5 ซม.',  
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-08-1.webp',
      '/assets/images/product/bracelet/bracelet-08-2.webp'
    ]
  },
  { 
    id: 309,  
    name: 'สร้อยข้อมือบิดยุ่ง', 
    description: 'น้ำหนัก 3 บาท ยาว 18 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-09-1.webp',
      '/assets/images/product/bracelet/bracelet-09-2.webp'
    ]
  },
  { 
    id: 310,  
    name: 'สร้อยข้อมือเลทมังกร', 
    description: 'น้ำหนัก 2 บาท ยาว 20.5 ซม.',  
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-10-1.webp',
      '/assets/images/product/bracelet/bracelet-10-2.webp'
    ]
  },
  { 
    id: 311, 
    name: 'สร้อยข้อมือห่วงมัดกลางขนแมว', 
    description: 'น้ำหนัก 2 บาท ยาว 19 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-11-1.webp',
      '/assets/images/product/bracelet/bracelet-11-2.webp'
    ]
  },
  { 
    id: 312,  
    name: 'สร้อยข้อมือลายตามด ห้อยหัวใจ', 
    description: 'น้ำหนัก 2 บาท ยาว 19 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-12-1.webp',
      '/assets/images/product/bracelet/bracelet-12-2.webp'
    ]
  },
  { 
    id: 313,  
    name: 'สร้อยข้อมือโซ่ฝรั่งตุ้งติ้ง 3 กษัตริย์', 
    description: 'น้ำหนัก 2 บาท ยาว 17.5-20.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-13-1.webp',
      '/assets/images/product/bracelet/bracelet-13-2.webp'
    ]
  },
  { 
    id: 314, 
    name: 'สร้อยข้อมือบิดราชันย์', 
    description: 'น้ำหนัก 2 บาท ยาว 19 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-14-1.webp',
      '/assets/images/product/bracelet/bracelet-14-2.webp'
    ]
  },
  { 
    id: 315,  
    name: 'สร้อยข้อมือลายมินิมอล', 
    description: 'น้ำหนัก 2 บาท ยาว 17.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-15-1.webp',
      '/assets/images/product/bracelet/bracelet-15-2.webp'
    ]
  },
  { 
    id: 316,  
    name: 'สร้อยข้อมือเกล็ดมังกรเกลียวโป่ง ห้อยหัวใจ', 
    description: 'น้ำหนัก 1 บาท ยาว 16 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-16-1.webp',
      '/assets/images/product/bracelet/bracelet-16-2.webp'
    ]
  },
  { 
    id: 317, 
    name: 'สร้อยข้อมือเบนซ์แพ', 
    description: 'น้ำหนัก 1 บาท ยาว 17 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-17-1.webp',
      '/assets/images/product/bracelet/bracelet-17-2.webp'
    ]
  },
  { 
    id: 318, 
    name: 'สร้อยข้อมือเกล็ดมังกรคั่นตุ้มฉลุพิกุล', 
    description: 'น้ำหนัก 1 บาท ยาว 15.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-18-1.webp',
      '/assets/images/product/bracelet/bracelet-18-2.webp'
    ]
  },
  { 
    id: 319,  
    name: 'สร้อยข้อมือโปร่งปั๊ม', 
    description: 'น้ำหนัก 2 สลึง ยาว 16.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-19-1.webp',
      '/assets/images/product/bracelet/bracelet-19-2.webp'
    ]
  },
  { 
    id: 320, 
    name: 'สร้อยข้อมือลายโซ่ฝรั่งเศส CC', 
    description: 'น้ำหนัก 2 สลึง ยาว 15.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-20-1.webp',
      '/assets/images/product/bracelet/bracelet-20-2.webp'
    ]
  },
  { 
    id: 321,  
    name: 'สร้อยข้อมือลายเซเลบซิลีน', 
    description: 'น้ำหนัก 2 สลึง ยาว 15.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-21-1.webp',
      '/assets/images/product/bracelet/bracelet-21-2.webp'
    ]
  },
  { 
    id: 322, 
    name: 'สร้อยข้อมือเกลียวทาโร่คั่นตุ้ม', 
    description: 'น้ำหนัก 1 สลึง ยาว 17 ซม.',  
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-22-1.webp',
      '/assets/images/product/bracelet/bracelet-22-2.webp'
    ]
  },
  { 
    id: 323,  
    name: 'สร้อยข้อมือโซ่ฝรั่งเศสสลับลาย', 
    description: 'น้ำหนัก 1 สลึง ยาว 16.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-23-1.webp',
      '/assets/images/product/bracelet/bracelet-23-2.webp'
    ]
  },
  { 
    id: 324,  
    name: 'สร้อยข้อมือซีโอโปร่ง', 
    description: 'น้ำหนัก 1 สลึง ยาว 15.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-24-1.webp',
      '/assets/images/product/bracelet/bracelet-24-2.webp'
    ]
  },
  { 
    id: 325,  
    name: 'สร้อยข้อมือเบนซ์', 
    description: 'น้ำหนัก 1/2 สลึง ยาว 16.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-25-1.webp',
      '/assets/images/product/bracelet/bracelet-25-2.webp'
    ]
  },
  { 
    id: 326,  
    name: 'สร้อยข้อมือโซ่โปร่ง', 
    description: 'น้ำหนัก 1/2 สลึง ยาว 18 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-26-1.webp',
      '/assets/images/product/bracelet/bracelet-26-2.webp'
    ]
  },
  { 
    id: 327,  
    name: 'สร้อยข้อมือทาโร่คั่นหัวใจ', 
    description: 'น้ำหนัก 1/2 สลึง ยาว 16 ซม.',  
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/bracelet/bracelet-27-1.webp',
      '/assets/images/product/bracelet/bracelet-27-2.webp'
    ]
  },
];


export const Cnecklace = [
  { 
    id: 401, 
    name: 'สร้อยคอโซ่ตัน', 
    description: 'น้ำหนัก 1 บาท ยาว 16.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-01-1.webp',
      '/assets/images/product/cnecklace/cnecklace-01-2.webp'
    ]
  },
  { 
    id: 402, 
    name: 'สร้อยคอผ่าหวายทรงเครื่อง', 
    description: 'น้ำหนัก 1 บาท ยาว 17 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-02-1.webp',
      '/assets/images/product/cnecklace/cnecklace-02-2.webp'
    ]
  },
  { 
    id: 403, 
    name: 'สร้อยคอเบนซ์', 
    description: 'น้ำหนัก 1 บาท ยาว 16 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-03-1.webp',
      '/assets/images/product/cnecklace/cnecklace-03-2.webp'
    ]
  },
  { 
    id: 404, 
    name: 'สร้อยคอผ่าหวาย', 
    description: 'น้ำหนัก 1 บาท ยาว 17.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-04-1.webp',
      '/assets/images/product/cnecklace/cnecklace-04-2.webp'
    ]
  },

  { 
    id: 405, 
    name: 'สร้อยคอซีตรองตัน', 
    description: 'น้ำหนัก 2 สลึง ยาว 18.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-05-1.webp',
      '/assets/images/product/cnecklace/cnecklace-05-2.webp'
    ]
  },
  { 
    id: 406, 
    name: 'สร้อยคอตะไบ', 
    description: 'น้ำหนัก 2 สลึง ยาว 18 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-06-1.webp',
      '/assets/images/product/cnecklace/cnecklace-06-2.webp'
    ]
  },
  { 
    id: 407, 
    name: 'สร้อยคอโซ่กล่อง', 
    description: 'น้ำหนัก 2 สลึง ยาว 18.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-07-1.webp',
      '/assets/images/product/cnecklace/cnecklace-07-2.webp'
    ]
  },
  { 
    id: 408, 
    name: 'สร้อยคอผ่าหวาย', 
    description: 'น้ำหนัก 2 สลึง ยาว 16.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-08-1.webp',
      '/assets/images/product/cnecklace/cnecklace-08-2.webp'
    ]
  },
  { 
    id: 409,  
    name: 'สร้อยคอปล้องอ้อย', 
    description: 'น้ำหนัก 2 สลึง ยาว 18.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-09-1.webp',
      '/assets/images/product/cnecklace/cnecklace-09-2.webp'
    ]
  },
  { 
    id: 410, 
    name: 'สร้อยคอเบนซ์', 
    description: 'น้ำหนัก 1 สลึง ยาว 17 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-10-1.webp',
      '/assets/images/product/cnecklace/cnecklace-10-2.webp'
    ]
  },
  { 
    id: 411,  
    name: 'สร้อยคอห่วงคู่เกลียว', 
    description: 'น้ำหนัก 1 สลึง ยาว 16 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-11-1.webp',
      '/assets/images/product/cnecklace/cnecklace-11-2.webp'
    ]
  },
  { 
    id: 412,  
    name: 'สร้อยคอหวายทรงเครื่อง', 
    description: 'น้ำหนัก 1 สลึง ยาว 17 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-12-1.webp',
      '/assets/images/product/cnecklace/cnecklace-12-2.webp'
    ]
  },
  { 
    id: 413, 
    name: 'สร้อยคอห่วงคู่ตัน', 
    description: 'น้ำหนัก 1 สลึง ยาว 17.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-13-1.webp',
      '/assets/images/product/cnecklace/cnecklace-13-2.webp'
    ]
  },
  { 
    id: 414,  
    name: 'สร้อยคอโซ่ฝรั่งเศส', 
    description: 'น้ำหนัก 1 สลึง ยาว 16.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-14-1.webp',
      '/assets/images/product/cnecklace/cnecklace-14-2.webp'
    ]
  },
  { 
    id: 415,  
    name: 'สร้อยคอโซ่ซิก้า', 
    description: 'น้ำหนัก 1 สลึง ยาว 18 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-15-1.webp',
      '/assets/images/product/cnecklace/cnecklace-15-2.webp'
    ]
  },
  { 
    id: 416, 
    name: 'สร้อยคอห่วงคู่โปร่ง', 
    description: 'น้ำหนัก 1 สลึง ยาว 16.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-16-1.webp',
      '/assets/images/product/cnecklace/cnecklace-16-2.webp'
    ]
  },
  { 
    id: 417, 
    name: 'สร้อยคอโซ่คชกริต', 
    description: 'น้ำหนัก 1 สลึง ยาว 15.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-17-1.webp',
      '/assets/images/product/cnecklace/cnecklace-17-2.webp'
    ]
  },
  { 
    id: 418, 
    name: 'สร้อยคอหวายคั่นโอ่ง', 
    description: 'น้ำหนัก 2 สลึง ยาว 16.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-18-1.webp',
      '/assets/images/product/cnecklace/cnecklace-18-2.webp'
    ]
  },
  { 
    id: 419,  
    name: 'สร้อยแขนผ่าหวาย', 
    description: 'น้ำหนัก 1 บาท ยาว 13 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-19-1.webp',
      '/assets/images/product/cnecklace/cnecklace-19-2.webp'
    ]
  },
  { 
    id: 420, 
    name: 'สร้อยแขนห่วงบิด', 
    description: 'น้ำหนัก 1 บาท ยาว 13.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-20-1.webp',
      '/assets/images/product/cnecklace/cnecklace-20-2.webp'
    ]
  },
  { 
    id: 421,  
    name: 'สร้อยแขนโซ่ฝรั่ง', 
    description: 'น้ำหนัก 1 บาท ยาว 14 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-21-1.webp',
      '/assets/images/product/cnecklace/cnecklace-21-2.webp'
    ]
  },
  { 
    id: 422, 
    name: 'สร้อยแขนทาโร่', 
    description: 'น้ำหนัก 2 สลึง ยาว 12.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-22-1.webp',
      '/assets/images/product/cnecklace/cnecklace-22-2.webp'
    ]
  },
  { 
    id: 423,  
    name: 'สร้อยแขนแพโลร็องต์', 
    description: 'น้ำหนัก 2 สลึง ยาว 12 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-23-1.webp',
      '/assets/images/product/cnecklace/cnecklace-23-2.webp'
    ]
  },
  { 
    id: 424,  
    name: 'สร้อยแขนโซ่คชกริต', 
    description: 'น้ำหนัก 1 สลึง ยาว 12.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-24-1.webp',
      '/assets/images/product/cnecklace/cnecklace-24-2.webp'
    ]
  },
  { 
    id: 425,  
    name: 'สร้อยแขนตุ้มขัดเงา', 
    description: 'น้ำหนัก 1 สลึง ยาว 12.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-25-1.webp',
      '/assets/images/product/cnecklace/cnecklace-25-2.webp'
    ]
  },
  { 
    id: 426,  
    name: 'สร้อยแขนซีตรอง', 
    description: 'น้ำหนัก 1 สลึง ยาว 12 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-26-1.webp',
      '/assets/images/product/cnecklace/cnecklace-26-2.webp'
    ]
  },
  { 
    id: 427,  
    name: 'กำไลข้อเท้าเด็ก', 
    description: 'น้ำหนัก 1 บาท', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/cnecklace/cnecklace-27-1.webp',
      '/assets/images/product/cnecklace/cnecklace-27-2.webp'
    ]
  }
];


export const Earring = [
  { 
    id: 501, 
    name: 'ต่างหูดอกกุหลาบ', 
    description: 'น้ำหนัก 11.1 กรัม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-01-1.webp',
      '/assets/images/product/earring/earring-01-2.webp'
    ]
  },
  { 
    id: 502, 
    name: 'ต่างหูดอกชมนาดลงยา', 
    description: 'น้ำหนัก 6.4 กรัม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-02-1.webp',
      '/assets/images/product/earring/earring-02-2.webp'
    ]
  },
  { 
    id: 503, 
    name: 'ต่างหูดอกแก้วกัลยา', 
    description: 'น้ำหนัก 5.9 กรัม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-03-1.webp',
      '/assets/images/product/earring/earring-03-2.webp'
    ]
  },
  { 
    id: 504, 
    name: 'ต่างหูดอกไม้ลงยา', 
    description: 'น้ำหนัก 5.2 กรัม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-04-1.webp',
      '/assets/images/product/earring/earring-04-2.webp'
    ]
  },

  { 
    id: 505, 
    name: 'ต่างหูสุโขทัยหัวใจลงยา', 
    description: 'น้ำหนัก 4.5 กรัม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-05-1.webp',
      '/assets/images/product/earring/earring-05-2.webp'
    ]
  },
  { 
    id: 506, 
    name: 'ต่างหู Chanel', 
    description: 'น้ำหนัก 2 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-06-1.webp',
      '/assets/images/product/earring/earring-06-2.webp'
    ]
  },
  { 
    id: 507, 
    name: 'ต่างหูสุโขทัยลงยา', 
    description: 'น้ำหนัก 2 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-07-1.webp',
      '/assets/images/product/earring/earring-07-2.webp'
    ]
  },
  { 
    id: 508, 
    name: 'ต่างหูห่วง', 
    description: 'น้ำหนัก 2 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-08-1.webp',
      '/assets/images/product/earring/earring-08-2.webp'
    ]
  },
  { 
    id: 509,  
    name: 'ต่างหูแวนคีฟลงยา', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-09-1.webp',
      '/assets/images/product/earring/earring-09-2.webp'
    ]
  },
  { 
    id: 510,  
    name: 'ต่างหู louis vuitton', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-10-1.webp',
      '/assets/images/product/earring/earring-10-2.webp'
    ]
  },
  { 
    id: 511, 
    name: 'ต่างหู hermes ลงยา', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-11-1.webp',
      '/assets/images/product/earring/earring-11-2.webp'
    ]
  },
  { 
    id: 512,  
    name: 'ต่างหูโลมาคู่', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-12-1.webp',
      '/assets/images/product/earring/earring-12-2.webp'
    ]
  },
  { 
    id: 513,  
    name: 'ต่างหูหัวใจ', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-13-1.webp',
      '/assets/images/product/earring/earring-13-2.webp'
    ]
  },
  { 
    id: 514, 
    name: 'ต่างหูหัวใจคู่', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-14-1.webp',
      '/assets/images/product/earring/earring-14-2.webp'
    ]
  },
  { 
    id: 515,  
    name: 'ต่างหูระย้า infinity', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-15-1.webp',
      '/assets/images/product/earring/earring-15-2.webp'
    ]
  },
  { 
    id: 516,  
    name: 'ต่างหู prada ฝังเพชร', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-16-1.webp',
      '/assets/images/product/earring/earring-16-2.webp'
    ]
  },
  { 
    id: 517, 
    name: 'ต่างหูห่วง', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-17-1.webp',
      '/assets/images/product/earring/earring-17-2.webp'
    ]
  },
  { 
    id: 518, 
    name: 'ต่างหูซีลีนลงยา', 
    description: 'น้ำหนัก ครึ่งสลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-18-1.webp',
      '/assets/images/product/earring/earring-18-2.webp'
    ]
  },
  { 
    id: 519,  
    name: 'ต่างหูพัดล้อมเพชร', 
    description: 'น้ำหนัก ครึ่งสลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-19-1.webp',
      '/assets/images/product/earring/earring-19-2.webp'
    ]
  },
  { 
    id: 520, 
    name: 'ต่างหูระย้า 2 กษัตริย์', 
    description: 'น้ำหนัก ครึ่งสลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-20-1.webp',
      '/assets/images/product/earring/earring-20-2.webp'
    ]
  },
  { 
    id: 521,  
    name: 'ต่างหูดาว', 
    description: 'น้ำหนัก ครึ่งสลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-21-1.webp',
      '/assets/images/product/earring/earring-21-2.webp'
    ]
  },
  { 
    id: 522, 
    name: 'ต่างหูปลาคาร์ฟ', 
    description: 'น้ำหนัก ครึ่งสลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-22-1.webp',
      '/assets/images/product/earring/earring-22-2.webp'
    ]
  },
  { 
    id: 523,  
    name: 'ต่างหูระย้าหัวใจ', 
    description: 'น้ำหนัก ครึ่งสลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-23-1.webp',
      '/assets/images/product/earring/earring-23-2.webp'
    ]
  },
  { 
    id: 524,  
    name: 'ต่างหูระย้า hermes', 
    description: 'น้ำหนัก ครึ่งสลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-24-1.webp',
      '/assets/images/product/earring/earring-24-2.webp'
    ]
  },
  { 
    id: 525,  
    name: 'ต่างหูห่วง', 
    description: 'น้ำหนัก ครึ่งสลึง',  
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-25-1.webp',
      '/assets/images/product/earring/earring-25-2.webp'
    ]
  },
  { 
    id: 526,  
    name: 'ต่างหูห่วงจิกเพชร', 
    description: 'น้ำหนัก ครึ่งสลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-26-1.webp',
      '/assets/images/product/earring/earring-26-2.webp'
    ]
  },
  { 
    id: 527,  
    name: 'ต่างหูดอกไม้ลงยา', 
    description: 'น้ำหนัก 1 กรัม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/earring/earring-27-1.webp',
      '/assets/images/product/earring/earring-27-2.webp'
    ]
  }
];


export const Jewelry99 = [
  { 
    id: 601, 
    name: 'ทองรูปพรรณ 99.99% สุโขทัย', 
    description: 'น้ำหนัก 132.85 กรัม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/jewelry99/jewelry99-01-1.webp',
      '/assets/images/product/jewelry99/jewelry99-01-2.webp'
    ]
  },
  { 
    id: 602, 
    name: 'ทองรูปพรรณ 99.99% สุโขทัย', 
    description: 'น้ำหนัก 124.69 กรัม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/jewelry99/jewelry99-02-1.webp',
      '/assets/images/product/jewelry99/jewelry99-02-2.webp'
    ]
  },
  { 
    id: 603, 
    name: 'ทองรูปพรรณ 99.99% ระย้าดอกชบา', 
    description: 'น้ำหนัก 118.55 กรัม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/jewelry99/jewelry99-03-1.webp',
      '/assets/images/product/jewelry99/jewelry99-03-2.webp'
    ]
  },
  { 
    id: 604, 
    name: 'ทองรูปพรรณ 99.99% หัวใจพันเครือ', 
    description: 'น้ำหนัก 106.8 กรัม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/jewelry99/jewelry99-04-1.webp',
      '/assets/images/product/jewelry99/jewelry99-04-2.webp'
    ]
  },

  { 
    id: 605, 
    name: 'ทองรูปพรรณ 99.99% สุโขทัย', 
    description: 'น้ำหนัก 46.03 กรัม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/jewelry99/jewelry99-05-1.webp',
      '/assets/images/product/jewelry99/jewelry99-05-2.webp'
    ]
  },
  { 
    id: 606, 
    name: 'ทองรูปพรรณ 99.99% หัวใจพันเครือ', 
    description: 'น้ำหนัก 20.16 กรัม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/jewelry99/jewelry99-06-1.webp',
      '/assets/images/product/jewelry99/jewelry99-06-2.webp'
    ]
  },
  { 
    id: 607, 
    name: 'ทองรูปพรรณ 99.99% ปี่เซียะเรียกทรัพย์', 
    description: 'น้ำหนัก 10.62 กรัม ยาว 5 ซม. กว้าง 2.5 ซม. สูง 2.2 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/jewelry99/jewelry99-07-1.webp',
      '/assets/images/product/jewelry99/jewelry99-07-2.webp'
    ]
  },
  { 
    id: 608, 
    name: 'ทองรูปพรรณ 99.99% ปี่เซียะเรียกทรัพย์', 
    description: 'น้ำหนัก 10.35 กรัม ยาว 5 ซม. กว้าง 2.5 ซม. สูง 2 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/jewelry99/jewelry99-08-1.webp',
      '/assets/images/product/jewelry99/jewelry99-08-2.webp'
    ]
  },
  { 
    id: 609, 
    name: 'ทองรูปพรรณ 99.99% ปี่เซียะเรียกทรัพย์', 
    description: 'น้ำหนัก 5.06 กรัม ยาว 3.5 ซม. กว้าง 1.6 ซม. สูง 1.5 ซม.', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/jewelry99/jewelry99-09-1.webp',
      '/assets/images/product/jewelry99/jewelry99-09-2.webp'
    ]
  }
];


export const MadeOrder = [
  { 
    id: 701, 
    name: 'พระปิดตา', 
    description: 'กรอบพระ', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-01-1.webp',
      '/assets/images/product/madeorder/madeorder-01-2.webp'
    ]
  },
  { 
    id: 702, 
    name: 'หลวงปู่ทวดวัดห้วยมงคล', 
    description: 'กรอบพระ', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-02-1.webp',
      '/assets/images/product/madeorder/madeorder-02-2.webp'
    ]
  },
  { 
    id: 703, 
    name: 'พระร่วง', 
    description: 'กรอบพระ', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    imgSrc: '/assets/images/product/madeorder/madeorder-06-1.webp',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-03-1.webp',
      '/assets/images/product/madeorder/madeorder-03-2.webp'
    ]
  },
  { 
    id: 704, 
    name: 'พระเหรียญ ทรงกลม', 
    description: 'กรอบพระ', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-04-1.webp',
      '/assets/images/product/madeorder/madeorder-04-2.webp'
    ]
  },

  { 
    id: 705, 
    name: 'พระเสมา', 
    description: 'กรอบพระ', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-05-1.webp',
      '/assets/images/product/madeorder/madeorder-05-2.webp'
    ]
  },
  { 
    id: 706, 
    name: 'พระเหรียญ', 
    description: 'กรอบพระ', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-06-1.webp',
      '/assets/images/product/madeorder/madeorder-06-2.webp'
    ]
  },
  { 
    id: 707, 
    name: 'ขุนแผน', 
    description: 'กรอบพระ', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-07-1.webp',
      '/assets/images/product/madeorder/madeorder-07-2.webp'
    ]
  },
  { 
    id: 708, 
    name: 'พระสมเด็จ', 
    description: 'กรอบพระ', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-08-1.webp',
      '/assets/images/product/madeorder/madeorder-08-2.webp'
    ]
  },
  { 
    id: 709,  
    name: 'นางพญา', 
    description: 'กรอบพระ', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-09-1.webp',
      '/assets/images/product/madeorder/madeorder-09-2.webp'
    ]
  },
  { 
    id: 710,  
    name: 'ตลับพระ พระสมเด็จ', 
    description: 'กรอบพระ ยกซุ้ม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-10-1.webp',
      '/assets/images/product/madeorder/madeorder-10-2.webp'
    ]
  },
  { 
    id: 711, 
    name: 'รูปไข่', 
    description: 'กรอบพระ ยกซุ้ม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-11-1.webp',
      '/assets/images/product/madeorder/madeorder-11-2.webp'
    ]
  },
  { 
    id: 712,  
    name: 'พระกริ่ง', 
    description: 'กรอบพระ ยกซุ้ม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-12-1.webp',
      '/assets/images/product/madeorder/madeorder-12-2.webp'
    ]
  },
  { 
    id: 713,  
    name: 'หลวงปู่ทวด', 
    description: 'กรอบพระ ยกซุ้ม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-13-1.webp',
      '/assets/images/product/madeorder/madeorder-13-2.webp'
    ]
  },
  { 
    id: 714, 
    name: 'รูปไข่', 
    description: 'กรอบพระ ยกซุ้ม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-14-1.webp',
      '/assets/images/product/madeorder/madeorder-14-2.webp'
    ]
  },
  { 
    id: 715,  
    name: 'รูปไข่', 
    description: 'กรอบพระ ยกซุ้ม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-15-1.webp',
      '/assets/images/product/madeorder/madeorder-15-2.webp'
    ]
  },
  { 
    id: 716,  
    name: 'ทรงเสมา', 
    description: 'กรอบพระ ยกซุ้ม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-16-1.webp',
      '/assets/images/product/madeorder/madeorder-16-2.webp'
    ]
  },
  { 
    id: 717, 
    name: 'พระทรงกลม', 
    description: 'กรอบพระ ยกซุ้ม ลงยา', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-17-1.webp',
      '/assets/images/product/madeorder/madeorder-17-2.webp'
    ]
  },
  { 
    id: 718, 
    name: 'สมเด็จ', 
    description: 'กรอบพระ ยกซุ้ม ลงยา', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-18-1.webp',
      '/assets/images/product/madeorder/madeorder-18-2.webp'
    ]
  },
  { 
    id: 719,  
    name: 'ขุนแผน', 
    description: 'กรอบพระ ยกซุ้ม ลงยา', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/madeorder/madeorder-19-1.webp',
      '/assets/images/product/madeorder/madeorder-19-2.webp'
    ]
  }
];


export const Pendant = [
  { 
    id: 801, 
    name: 'จี้หัวใจโปร่งจิกเพชร', 
    description: 'น้ำหนัก 1 บาท', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-01-1.webp',
      '/assets/images/product/pendant/pendant-01-2.webp'
    ]
  },
  { 
    id: 802, 
    name: 'จี้ CO 2 กษัตริย์', 
    description: 'น้ำหนัก 1 บาท', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-02-1.webp',
      '/assets/images/product/pendant/pendant-02-2.webp'
    ]
  },
  { 
    id: 803, 
    name: 'จี้ลูกคิด', 
    description: 'น้ำหนัก 2 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    imgSrc: '/assets/images/product/pendant/pendant-06-1.webp',
    additionalImages: [
      '/assets/images/product/pendant/pendant-03-1.webp',
      '/assets/images/product/pendant/pendant-03-2.webp'
    ]
  },
  { 
    id: 804, 
    name: 'จี้พระพิฆเนศ', 
    description: 'น้ำหนัก 2 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-04-1.webp',
      '/assets/images/product/pendant/pendant-04-2.webp'
    ]
  },

  { 
    id: 805, 
    name: 'จี้ดอกไม้ฉลุ', 
    description: 'น้ำหนัก 2 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-05-1.webp',
      '/assets/images/product/pendant/pendant-05-2.webp'
    ]
  },
  { 
    id: 806, 
    name: 'จี้ CO 2 กษัตริย์', 
    description: 'น้ำหนัก 2 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-06-1.webp',
      '/assets/images/product/pendant/pendant-06-2.webp'
    ]
  },
  { 
    id: 807, 
    name: 'จี้ ร.5', 
    description: 'น้ำหนัก 2 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-07-1.webp',
      '/assets/images/product/pendant/pendant-07-2.webp'
    ]
  },
  { 
    id: 808, 
    name: 'จี้ตำลึง', 
    description: 'น้ำหนัก 2 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-08-1.webp',
      '/assets/images/product/pendant/pendant-08-2.webp'
    ]
  },
  { 
    id: 809,  
    name: 'จี้ม้า', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-09-1.webp',
      '/assets/images/product/pendant/pendant-09-2.webp'
    ]
  },
  { 
    id: 810,  
    name: 'จี้พระประจำวัน', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-10-1.webp',
      '/assets/images/product/pendant/pendant-10-2.webp'
    ]
  },
  { 
    id: 811, 
    name: 'จี้ chanel ล้อมเพชร', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-11-1.webp',
      '/assets/images/product/pendant/pendant-11-2.webp'
    ]
  },
  { 
    id: 812,  
    name: 'จี้หลวงปู่ทวดล้อมเพชร', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-12-1.webp',
      '/assets/images/product/pendant/pendant-12-2.webp'
    ]
  },
  { 
    id: 813,  
    name: 'จี้ไม้กางเขน', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-13-1.webp',
      '/assets/images/product/pendant/pendant-13-2.webp'
    ]
  },
  { 
    id: 814, 
    name: 'จี้หัวใจ CO 2 กษัตริย์', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-14-1.webp',
      '/assets/images/product/pendant/pendant-14-2.webp'
    ]
  },
  { 
    id: 815,  
    name: 'จี้ ร.9', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-15-1.webp',
      '/assets/images/product/pendant/pendant-15-2.webp'
    ]
  },
  { 
    id: 816,  
    name: 'จี้หัวใจพิกุล', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-16-1.webp',
      '/assets/images/product/pendant/pendant-16-2.webp'
    ]
  },
  { 
    id: 817, 
    name: 'จี้ดอกไม้ฉลุ 2 กษัตริย์', 
    description: 'น้ำหนัก 1 สลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-17-1.webp',
      '/assets/images/product/pendant/pendant-17-2.webp'
    ]
  },
  { 
    id: 818, 
    name: 'จี้ลูกบอลดิสโก้ 2 กษัตริย์', 
    description: 'น้ำหนัก ครึ่งสลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-18-1.webp',
      '/assets/images/product/pendant/pendant-18-2.webp'
    ]
  },
  { 
    id: 819,  
    name: 'จี้ถุงเงิน', 
    description: 'น้ำหนัก ครึ่งสลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-19-1.webp',
      '/assets/images/product/pendant/pendant-19-2.webp'
    ]
  },
  { 
    id: 820, 
    name: 'จี้ LV', 
    description: 'น้ำหนัก ครึ่งสลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-20-1.webp',
      '/assets/images/product/pendant/pendant-20-2.webp'
    ]
  },
  { 
    id: 821,  
    name: 'จี้หงส์ฝังเพชร', 
    description: 'น้ำหนัก ครึ่งสลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-21-1.webp',
      '/assets/images/product/pendant/pendant-21-2.webp'
    ]
  },
  { 
    id: 822, 
    name: 'จี้ผีเสื้อ', 
    description: 'น้ำหนัก ครึ่งสลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-22-1.webp',
      '/assets/images/product/pendant/pendant-22-2.webp'
    ]
  },
  { 
    id: 823,  
    name: 'จี้ CO 2 กษัตริย์', 
    description: 'น้ำหนัก ครึ่งสลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-23-1.webp',
      '/assets/images/product/pendant/pendant-23-2.webp'
    ]
  },
  { 
    id: 824,  
    name: 'จี้ chanel', 
    description: 'น้ำหนัก ครึ่งสลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-24-1.webp',
      '/assets/images/product/pendant/pendant-24-2.webp'
    ]
  },
  { 
    id: 825,  
    name: 'จี้ LOVE', 
    description: 'น้ำหนัก ครึ่งสลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-25-1.webp',
      '/assets/images/product/pendant/pendant-25-2.webp'
    ]
  },
  { 
    id: 826,  
    name: 'จี้ดาว 2 กษัตริย์', 
    description: 'น้ำหนัก ครึ่งสลึง', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-26-1.webp',
      '/assets/images/product/pendant/pendant-26-2.webp'
    ]
  },
  { 
    id: 527,  
    name: 'จี้ปี่เซียะ', 
    description: 'น้ำหนัก 15.78 กรัม', 
    details: 'ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร',
    additionalImages: [
      '/assets/images/product/pendant/pendant-27-1.webp',
      '/assets/images/product/pendant/pendant-27-2.webp'
    ]
  }
];

