import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Breadcrumb from "../common/Breadcrumb";
import "react-image-lightbox/style.css";
import MenuBar from '../../blocks/MenuBar';

const dataList = [
    { colume1: '• ทองคำแท่ง 99.99% หนึ่งกิโลกรัม เท่ากับ 65.6 บาท (32.150722 ทรอนออนซ์)', },
    { colume1: '• 1 ทรอยออนซ์ เท่ากับ 31.1034768 กรัม', },
    { colume1: '• ซื้อ-ขายได้ตั้งแต่ 100 กรัม และ 1 กิโลกรัมขึ้นไป', }
];


class Gold99 extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='ทองคำแท่ง 99.99% LBMA' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />
                <Breadcrumb title={'ทองคำแท่ง 99.99% LBMA'} bgimage={'/assets/images/breadcrumb/breadcrumb-gold99.webp'}   description={'ทองคำแท่ง 99.99% LBMA ที่ออกแบบอย่างประณีต พิถีพิถัน ที่ออกแบบเหมาะกับการเก็บรักษาเพื่อเก็งกำไร หรือมอบเป็นของขวัญอันล้ำค่าในโอกาสพิเศษ เรียบหรู โดดเด่น คุณภาพพรีเมี่ยม ห้างทองเยาวราช1 ยโสธร พร้อมบริการด้วยความใส่ใจ'}/>

                <div className="product-area">
                    <div className="gold ptb--50">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                                    <div className="img">
                                        <img src={`/assets/images/product/gold99/gold99-01.webp`} alt="ทองคำแท่ง 99.99% LBMA" />
                                    </div>
                                    <div className="section-title mb--20 mt--20">
                                        <h3 className="title">ทองคำ 99.99% มาตรฐาน LBMA</h3>
                                        <p className="description">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ห้างทองเยาวราช 1 ยโสธร เป็นตัวแทนซื้อขายทองคำแท่ง ของบริษัทชายน์นิ่งโกลด์ บูลเลี่ยน จำกัด ในการซื้อขายทองคำแท่ง 99.99% ซึ่งสามารถซื้อขายได้ตั้งแต่ 1 กิโลขึ้นไป</p>
                                    </div>

                                    <div>
                                        <table className="dataTable">
                                            <tbody>
                                                {dataList.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="col-12">{item.colume1}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="image-row">
                                        <div className="img">
                                            <img src={`/assets/images/product/gold99/gold99-02.webp`} alt="ทองคำแท่ง 99.99% LBMA" />
                                            <img src={`/assets/images/product/gold99/gold99-03.webp`} alt="ทองคำแท่ง 99.99% LBMA" />
                                            <img src={`/assets/images/product/gold99/gold99-04.webp`} alt="ทองคำแท่ง 99.99% LBMA" />
                                            <img src={`/assets/images/product/gold99/gold99-05.webp`} alt="ทองคำแท่ง 99.99% LBMA" />
                                            <img src={`/assets/images/product/gold99/gold99-06.webp`} alt="ทองคำแท่ง 99.99% LBMA" />
                                            <img src={`/assets/images/product/gold99/gold99-07.webp`} alt="ทองคำแท่ง 99.99% LBMA" />
                                            <img src={`/assets/images/product/gold99/gold99-08.webp`} alt="ทองคำแท่ง 99.99% LBMA" />
                                            <img src={`/assets/images/product/gold99/gold99-09.webp`} alt="ทองคำแท่ง 99.99% LBMA" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt--40">
                                    <MenuBar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Back To Top */}
                < div className="backto-top" >
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div >
                {/* End Back To Top */}
                <Footer />
            </React.Fragment>
        )
    }
}
export default Gold99;