import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Breadcrumb from "../common/Breadcrumb";

import ProductMenu from '../../blocks/ProductMenu';
import { BrowserRouter as Router } from 'react-router-dom';

class Bangle extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='กำไลแขน' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />
                <Breadcrumb title={'กำไลแขน'} bgimage={'/assets/images/breadcrumb/breadcrumb-bangle.webp'} description={'กำไลแขน ที่ออกแบบอย่างประณีต พิถีพิถัน เหมาะกับทุกไลฟ์สไตล์ ไม่ว่าจะสวมใส่เองหรือมอบเป็นของขวัญอันล้ำค่าในโอกาสพิเศษ เรียบหรู โดดเด่น คุณภาพพรีเมี่ยม ห้างทองเยาวราช1 ยโสธร พร้อมบริการด้วยความใส่ใจ'}/>

                {/* ---------------------------------------- */}

                <Router>
                    <ProductMenu product_category={'Bangle'} />
                </Router>

                {/* ---------------------------------------- */}

                {/* Start Back To Top */}
                < div className="backto-top" >
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div >
                {/* End Back To Top */}
                <Footer />
            </React.Fragment>
        )
    }
}
export default Bangle;