
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Necklace, Ring, Bangle, Bracelet, Cnecklace, Earring, Jewelry99, MadeOrder, Pendant } from './products';
import { FaLine } from "react-icons/fa";
import PageHelmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

const ProductDetails = () => {


  const { id } = useParams();
  const product = [...Necklace, ...Ring, ...Bangle, ...Bracelet, ...Cnecklace, ...Earring, ...Jewelry99, ...MadeOrder, ...Pendant].find((p) => p.id === parseInt(id));
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  if (!product) {
    return <div>Product not found</div>;
  }

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + product.additionalImages.length) % product.additionalImages.length);
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % product.additionalImages.length);
  };

  return (
    <React.Fragment>
      <PageHelmet pageTitle='สร้อยคอ' />
      <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />
      <div className="product-details-area">
        <div className="product-details">
          <div className="product-image-container">
            <button className="nav-button left" onClick={handlePrevClick}>&lt;</button>
            <img src={product.additionalImages[currentImageIndex]} alt={product.name} className="main-image" />
            <button className="nav-button right" onClick={handleNextClick}>&gt;</button>
            <div className="additional-images">
              {product.additionalImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${product.name} ${index + 1}`}
                  onClick={() => setCurrentImageIndex(index)}
                  className={`thumbnail ${index === currentImageIndex ? 'active' : ''}`}
                />
              ))}
            </div>
          </div>
          <div className="product-info">
            {/* <Link to="" className="back-link">กลับ</Link> */}
            <h2>{product.name}</h2>
            <p className="product-description">{product.description}</p>
            {/* <p className="product-details">{product.details}</p> */}
            <p className="product-details">•&nbsp;&nbsp;ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร<br />
              •&nbsp;&nbsp;รับงานสั่งทำทุกประเภท เพชร พลอย ทอง 99.99%, 96.5%, 90% และงานเลี่ยมกรอบพระสำเร็จ 90% หรือกรอบพระฝังเพชรแท้หรือเพชรรัสเซียทั้งแบบลงยาและไม่ลงยา กรอบพระยกซุ้มแบบลงยาและไม่ลงย่า<br />
              •&nbsp;&nbsp;รับประกันราคาทั้งซื้อและขายที่ลูกค้าพอใจ ทั้งราคาปลีก และราคาส่ง<br />
              •&nbsp;&nbsp;ห้างทองเยาวราช 1 ยโสธร รับซื้อและขายทองคำแท่งทั้ง 96.5 % และ 99.99% ทุกแบบและทุกยี่ห้อ โดยเราเป็นตัวแทนจำหน่าย ของ บ. ชายน์นิ่งโกลด์ บูลเลี่ยน จำกัด<br />
            </p>
            <a href="https://bit.ly/2XBIm9d" target="_blank" rel="noopener noreferrer"><button className="chat-shop-button"><FaLine className="icon" />&nbsp;&nbsp;สอบถามเพิ่มเติม</button></a>
          </div>
        </div>
      </div>

      {/* Start Back To Top */}
      < div className="backto-top" >
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div >
      {/* End Back To Top */}
      <Footer />
    </React.Fragment>
  );
};

export default ProductDetails;
