import React, { useState } from "react";

const Accordion = () => {
    const faqs = [
        {
            key: 1,
            question: "ราคาทองรูปพรรณ ต่อรองได้มั้ยคะ ไม่ว่าจะมาซื้อหรือขายทอง?",
            answer: "ราคาหน้าร้านที่แจ้งลูกค้าไม่ว่าจะเป็นฝั่งซื้อหรือขายสามารถต่อรองได้ค่ะ ลูกค้าสามารถเชื่อมั่นได้ว่า จะได้รับความพอใจในราคากับทางร้านแน่นอนค่ะ"
        },
        {
            key: 2,
            question: "ที่ร้านรับซื้อกรอบพระมั้ยคะ ถ้ารับขายได้เท่าไรคะ?",
            answer: "ที่ร้านรับซื้อกรอบพระ 90 ราคารับซื้อ ขึ้นอยู่กับน้ำหนักของกรอบ จำเป็นจะต้องแกะพระออกจากกรอบก่อน แล้วค่อยชั่งน้ำหนัก จะรับซื้อตามน้ำหนักจริงและราคาของทองวันนั้นๆ (การขายกรอบพระ ลูกค้าจำเป็นต้องตัดสินใจว่าต้องการขายจริง เพราะเมื่อได้แกะกรอบพระเพื่อชั่งน้ำหนักแล้ว ถ้าลูกค้าเปลี่ยนใจไม่ต้องการขาย ทางร้านไม่สามารถประกอบกรอบพระคืนให้ได้)"
        },
        {
            key: 3,
            question: "ที่ร้านรับซื้อทองจากต่างประเทศมั้ยคะ?",
            answer: "ที่ร้านรับซื้อทองจากต่างประเทศ ซื้อตามเปอร์เซ็นต์ทองตามจริง"
        },
        {
            key: 4,
            question: "ต้องการซื้อแหวนออนไลน์ แต่ไม่ทราบไซส์ และไม่มีเครื่องมือวัดนิ้ว พอจะมีวิธีวัดนิ้วแบบง่ายๆมั้ยคะ?",
            answer: "ลูกค้าสามารถตัดกระดาษ พันรอบนิ้วให้แน่นพอดี แล้ววัดออกมาเป็น cm แล้วแจ้งทางร้านได้เลย ทางร้านจะคำนวณขนาดแหวนที่พอดีนิ้วของลูกค้าให้ค่ะ"
        },
        {
            key: 5,
            question: "ทางร้านมีราคาตัวแทนมั้ยคะ ถ้ามี มีเงื่อนไขอะไรบ้างคะ?",
            answer: "ทางร้านรับตัวแทน ซึ่งจะให้ในราคาส่งทุกครั้ง ลูกค้าสามารถมาติดต่อทางร้าน แจ้งว่าจะเป็นตัวแทน และเปิดบิลกับทางร้านได้เลย"
        },
        {
            key: 6,
            question: "ทางร้านรับทำแหวนนามสกุลมั้ยคะ?",
            answer: "ทางร้านรับทำแหวนนามสกุล สร้อยคอและจี้นามสกุลค่ะ รายละเอียด จะอยู่ในหัวข้องานสั่งทำ ลูกค้าสามารถเข้าไปดูรายละเอียด และติดต่อทางร้านได้เลยค่ะ ระยะเวลาสั่งทำประมาณ 3-4 สัปดาห์"
        },
        {
            key: 7,
            question: "ทางร้านรับซื้อเพชรมั้ยคะ?",
            answer: "ทางร้านไม่ได้รับซื้อเพชรโดยตรง แต่เป็นการรับซื้อตัวเรือนทองที่อยู่กับเพชรค่ะ ถ้าลูกค้ามีความจำเป็นที่จะขายเพชรด้วยจริงๆ ทางร้านจะพิจารณาราคาตามความเหมาะสม"
        },
        {
            key: 8,
            question: "ทางร้านรับทำงานกรอบพระ ขึ้นรูปพิเศษมั้ยคะ?",
            answer: "ทางร้านรับทำงานสั่งทำกรอบพระ ทุกชนิด สามารถทำกรอบพระ ตามความต้องการของลูกค้าได้ทุกแบบไม่ว่าจะเป็น ทรงพิเศษต่างๆ ใส่เพชรแท้หรือเพชรรัสเซีย พลอยแท้หรือพลอยสังเคราะห์ ลงยาหรือไม่ลงยา ยกซุ้มหรือไม่ยกซุ้ม เพียงแค่ลูกค้าแจ้งรายละเอียดที่ต้องการ ระยะเวลาสั่งทำประมาณ 3-4 สัปดาห์"
        },
        {
            key: 9,
            question: "ถ้าต้องการเปิดออมทอง ไม่ทราบว่าต้องใช้หลักฐานอะไรบ้างคะ และถ้าไม่สะดวกไปชำระออมที่ร้านแต่ละครั้ง สามารถโอนเข้าบัญชีของทางร้านได้มั้ยคะ?",
            answer: "ลูกค้าสามารถเปิดออมออนไลน์ได้เลยค่ะ เพียงแค่แอดไลน์ติดต่อทางร้าน แล้วส่งบัตรประชาชนและเบอร์โทรเพื่อเปิดออม ทางร้านจะส่งเลขที่บัญชี พอลูกค้าโอนชำระและส่งสลิปหลักฐานการโอนมาให้แล้ว ทางร้านจะส่งใบเสร็จหลักฐานการออมแต่ละครั้งให้ลูกค้าค่ะ"
        },
        {
            key: 10,
            question: "ทางร้านรับซ่อมทองชำรุดมั้ยคะ?",
            answer: "ทางร้านรับซ่อมทองชำรุดค่ะ ราคาขึ้นกับน้ำหนักทองค่ะ ระยะเวลาประมาณ 30 นาทีต่อ 1 ชิ้น"
        },
        {
            key: 11,
            question: "ถ้ามีตัวอย่างลายทองที่ต้องการมาให้ทางร้าน สามารถสั่งได้ตามแบบมั้ยคะ?",
            answer: "ในกรณีที่ทางร้านไม่มีลายทองที่ลูกค้าต้องการ สามารถสั่งได้ตามแบบที่ลูกค้าต้องการได้ค่ะ ขึ้นอยู่กับว่า ลายทองนั้นๆ ช่างของเราสามารถขึ้นบล็อกผลิตได้มั้ย ซึ่งงานสั่งที่ผ่านมาส่วนใหญ่ ทางร้านผลิตให้ได้ทุกแบบค่ะ ระยะเวลาสั่งทำประมาณ 3-4 สัปดาห์"
        }
    ];

    const [isClickedArr, setIsClickedArr] = useState(Array(faqs.length).fill(false));

    const handleClick = (index) => {
        setIsClickedArr((prevClickedArr) => {
            const newClickedArr = [...prevClickedArr];
            newClickedArr[index] = !newClickedArr[index];

            if (newClickedArr[index]) {
                const prevDisplayIndex = prevClickedArr.findIndex((clicked) => clicked);
                if (prevDisplayIndex !== -1 && prevDisplayIndex !== index) {
                    newClickedArr[prevDisplayIndex] = false;
                }
            }

            return newClickedArr;
        });
    };

    return (
        <div className="faq">
            {faqs.map((faq, index) => {
                const questionClassName = isClickedArr[index] ? "question active" : "question";
                const questionContainerClassName = isClickedArr[index] ? "question-container active" : "question-container";
                const answerClassName = isClickedArr[index] ? "answer-display" : "answer";
                const arrowClassName = isClickedArr[index] ? "arrow-rotate" : "arrow";

                return (
                    <article className="faq-container" key={faq.key}>
                        <div className={questionContainerClassName} onClick={() => handleClick(index)}>
                            <p className={questionClassName}>{faq.question}</p>
                            <img
                                className={arrowClassName}
                                src="/assets/images/logo/icon-arrow-down.svg"
                                alt="icon arrow down"
                            />
                        </div>
                        <div className={answerClassName}>
                            <p>{faq.answer}</p>
                        </div>
                    </article>
                );
            })}
        </div>
    );
};

export default Accordion;
