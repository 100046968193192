import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Necklace, Ring, Bangle, Bracelet, Cnecklace, Earring, Jewelry99, MadeOrder, Pendant } from './products';

const ProductMenu = ({ product_category }) => {
  const [hoveredProduct, setHoveredProduct] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const history = useHistory();

  const handleMouseEnter = (id) => {
    const timeout = setTimeout(() => {
      setHoveredProduct(id);
    }, 0); // Delay of 1 second
    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    setHoveredProduct(null);
  };

  const handleProductClick = (id) => {
    history.push(`/product/${id}`);
    // Optional: Refresh the page
    var topPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    window.location.href = '';
    document.documentElement.scrollTop = topPos;
  };

  let products = [];
  if (product_category === 'Necklace') {
    products = Necklace;
  } else if (product_category === 'Ring') {
    products = Ring;
  } else if (product_category === 'Bangle') {
    products = Bangle;
  } else if (product_category === 'Bracelet') {
    products = Bracelet;
  } else if (product_category === 'Cnecklace') {
    products = Cnecklace;
  } else if (product_category === 'Earring') {
    products = Earring;
  } else if (product_category === 'Jewelry99') {
    products = Jewelry99;
  } else if (product_category === 'MadeOrder') {
    products = MadeOrder;
  } else if (product_category === 'Pendant') {
    products = Pendant;
  }

  return (
    <div className="product-menu">
      <div className="product-list">
        {products.map((product) => (
          <div
            className="product block col-xl-2 col-lg-3 col-md-4 col-sm-12 col-12"
            key={product.id}
          onMouseEnter={() => handleMouseEnter(product.id)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleProductClick(product.id)}
          >
            <div className="image-container">
              <img
                src={product.additionalImages[0]}
                alt={product.name}
              className={`product-image ${hoveredProduct === product.id ? 'fade-out' : 'fade-in'}`}
              />
              <img
                src={product.additionalImages[1]}
                alt={product.name}
              className={`product-image ${hoveredProduct === product.id ? 'fade-in' : 'fade-out'}`}
              />
            </div>
            <div className="product-info">
              <div className="product-name">{product.name}</div>
              <div className="product-description">{product.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductMenu;
