import PropTypes from "prop-types";
import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.pageTitle} - ห้างทองเยาวราช 1 ยโสธร</title>
                    <meta name="description" content="ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร ห้างทองเยาวราช 1 ยโสธร รับซื้อและขายทองคำแท่งทั้ง 96.5 % และ 99.99% ทุกแบบและทุกยี่ห้อ โดยเราเป็นตัวแทนจำหน่าย ของ บ. ชายน์นิ่งโกลด์ บูลเลี่ยน จำกัด" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                </Helmet>
            </React.Fragment>
        )
    }
}
PageHelmet.propTypes = {
    title: PropTypes.string
};
export default PageHelmet;
