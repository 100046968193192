import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class GoldSaver extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='ออมทอง' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />


                <div className="gold-saver-area">
                    <div className="gold-saver-bg" >
                    </div>
                    <div className="section-1 pb--60" >
                        <div className="container text-center">
                            <div className="gold-saver-top">
                                <div className="section-title">
                                    <div className="row">
                                        <h3 className="title-color">ออมทอง </h3>
                                        {/* <h3 className="title">คืออะไร?</h3> */}
                                    </div>
                                    <p className="description">คือการซื้อทองโดยทยอยเก็บสะสมน้ำหนักทองทีละเล็กละน้อย สามารถเริ่มต้นซื้อ ทองคำแท่ง หรือทองรูปพรรณ หรือสินค้าใดๆ ของทางร้านได้ด้วยจำนวนเงินไม่จำกัดขั้นต่ำ สามารถรับเป็นทองคำแท่งได้จริง
                                        เมื่อสะสมทองคำครบตามน้ำหนัก</p>

                                    <div className="row">
                                        <p className="description">ออมทองกับเรา ออมแบบไม่ล็อคราคา </p>
                                        <p className="description-color">ไม่จำกัดจำนวนเงิน ไม่จำกัดขั้นต่ำ</p>
                                        {/* <p className="description">ว่าแต่ละครั้งจะออมเท่าไหร่ ขึ้นกับความสะดวกของลูกค้า</p> */}
                                    </div>

                                    <div className="mt--20">
                                        <p className="description-list"><img className="icon" src="/assets/images/goldsaver/goldsaver-icon-1.webp" alt="ออมทอง" />คิดราคาทองในวันที่มารับทอง (เราจะจ่ายออมไปเรื่อยๆ วันไหนที่มารับทอง ถึงคิดราคาในวันนั้น) </p>
                                        <p className="description-list"><img className="icon" src="/assets/images/goldsaver/goldsaver-icon-1.webp" alt="ออมทอง" />วิธีนี้จะไม่มีกำหนดหมดอายุ (ออมไปได้เรื่อยๆ)</p>
                                        <p className="description-list"><img className="icon" src="/assets/images/goldsaver/goldsaver-icon-1.webp" alt="ออมทอง" />ไม่จำกัดจำนวนเงินว่าแต่ละครั้งจะออมเท่าไร ขึ้นกับความสะดวกของลูกค้า</p>
                                    </div>

                                    <div className="mt--20">
                                    <p className="description">หมายเหตุ กรณีที่ลูกค้าต้องการรับเงินคืน โดยเปลี่ยนใจไม่ขอรับเป็นทองแล้ว ทางร้านคิดค่าธรรมเนียม ขั้นต่ำ 10% จากยอดออมทั้งหมด ขึ้นกับจำนวนเงินของลูกค้า</p>
                                    </div>
                                </div>

                                <div className="col-12 mt--40 text-center">

                                    <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2XBIm9d" class="btn-default btn-hover-primary">เปิดบัญชีกับเรา</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" bg_color--1">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <a href="https://bit.ly/2XBIm9d" target="_blank" rel="noopener noreferrer"><img className="w-100" rel="noopener noreferrer" src="/assets/images/goldsaver/goldsaver-line-1.webp" alt="ออมทองกับห้างทองเยาวราช1 ยโสธร" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Back To Top */}
                < div className="backto-top" >
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div >
                {/* End Back To Top */}

                < Footer />

            </React.Fragment >
        )
    }
}
export default GoldSaver;
