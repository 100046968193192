import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Accordion from "../blocks/Accordion";

import { FaPhoneAlt, FaFacebook, FaLine, FaMapMarkedAlt } from "react-icons/fa";


class Contact extends Component {
    componentDidMount() {
        if (window.location.hash) {
            const element = document.getElementById(window.location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    handleFAQClick = (e) => {
        e.preventDefault();
        const faqElement = document.getElementById('faq');
        if (faqElement) {
            faqElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='ติดต่อเรา' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />

                {/* Start Contact Top Area  */}
                <div className="contact-area ptb--50">
                    <div className="contact-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title mb--30 text-center">
                                        <h3 className="title">ติดต่อสอบถามเพิ่มเติม</h3>
                                        <p className="description">ห้างทองเยาวราช 1 ยโสธร ยินดีให้บริการทุกท่าน</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="address">
                            <div className="ptb--30 bg_color--1">
                                <div className="container">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                <img src="/assets/images/contact/map-1.webp" alt="ห้างทองเยาวราช 1 ยโสธร" />
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                <h4>ห้างทองเยาวราช 1 ยโสธร สาขาตลาดใหม่</h4>
                                                <p>381/4 ถ.วิทยะธำรงค์ ต.ในเมือง อ.เมือง จ.ยโสธร 35000</p>
                                                <p><b>เวลาทำการ จันทร์ – อาทิตย์ 08.00 น. – 17.00 น.</b></p>
                                                <a href="tel:+6645 712 548" class="button mt--10"><FaPhoneAlt className="icon" />โทร 045 712 693</a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/yaowarat1yasothon" class="button mt--15"><FaFacebook className="icon" />Facebook</a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2XBIm9d" class="button mt--15"><FaLine className="icon" />Line</a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://maps.app.goo.gl/KHdpfHeQkgZb7Gk77" class="button mt--15"><FaMapMarkedAlt className="icon" />แผนที่ Google Map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ptb--30 bg_color--3">
                                <div className="container">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                <img src="/assets/images/contact/map-2.webp" alt="ห้างทองเยาวราช1 ยโสธร สาขา2 " />
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                <h4>ห้างทองเยาวราชยโสธร สาขาตลาดเก่า</h4>
                                                <p>288 290 ถ.อุทัยรามมฤทธิ์ ต.ในเมือง อ.เมือง จ.ยโสธร 35000</p>
                                                <p><b>เวลาทำการ จันทร์ – อาทิตย์ 08.00 น. – 17.00 น.</b></p>
                                                <div className="row">
                                                    <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12">
                                                        <a href="tel:+6645 712 548" class="button mt--10"><FaPhoneAlt className="icon" />โทร 045 712 781</a>
                                                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Yaowaradyaso" class="button mt--15"><FaFacebook className="icon" />Facebook</a>
                                                        <a target="_blank" rel="noopener noreferrer" href="https://line.me/R/ti/p/@464byixt" class="button mt--15"><FaLine className="icon" />Line ID: @464byixt</a>
                                                        <a target="_blank" rel="noopener noreferrer" href="https://maps.app.goo.gl/nDprwmhmmJT6mnie6" class="button mt--15"><FaMapMarkedAlt className="icon" />แผนที่ Google Map</a>
                                                    </div>
                                                    <div className="qr-contact col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12">
                                                        <img src="/assets/images/contact/qr-02.webp" alt="ห้างทองเยาวราช" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ptb--30 bg_color--1">
                                <div className="container">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                <img src="/assets/images/contact/map-3.webp" alt="ห้างทองทองแวววาว " />
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                <h4>ห้างทองทองแวววาว สาขาตลาดใหม่</h4>
                                                <p>381/35 ถ.วิทยะธำรงค์ ต.ในเมือง อ.เมือง จ.ยโสธร 35000</p>
                                                <p><b>เวลาทำการ จันทร์ – เสาร์ 08.00 น. – 17.00 น.</b></p>
                                                <a href="tel:+6645 712 548" class="button mt--10"><FaPhoneAlt className="icon" />โทร 045 712 103</a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/winnerygold" class="button mt--15"><FaFacebook className="icon" />Facebook</a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://line.me/R/ti/p/~sharkie2525" class="button mt--15"><FaLine className="icon" />Line ID: Sharkie2525</a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://maps.app.goo.gl/bEBcpXN4q1uPYhyr9" class="button mt--15"><FaMapMarkedAlt className="icon" />แผนที่ Google Map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Contact Top Area  */}


                </div>

                <section className="contact-area">
                    <div className="ptb--40">
                        <div className="contact-top">
                            <div className="container">
                                <div className="FAQ-section">
                                    <div className="section-title mb--30 text-left">
                                        <h3 className="title ">คำถามที่พบบ่อย</h3>
                                    </div>
                                    <Accordion />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Start Back To Top */}
                <div className="backto-top" >
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
            </React.Fragment >
        )
    }
}
export default Contact