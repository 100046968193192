import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Breadcrumb from "../common/Breadcrumb";
import ProductMenu from '../../blocks/ProductMenu';
import { BrowserRouter as Router } from 'react-router-dom';


class MadeOrder extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='งานสั่งทำ' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />
                <Breadcrumb title={'งานสั่งทำ'} bgimage={'/assets/images/breadcrumb/breadcrumb-madeorder.webp'}  description={'งานสั่งทำที่ออกแบบอย่างประณีต พิถีพิถัน เหมาะกับทุกไลฟ์สไตล์ ไม่ว่าจะสวมใส่เองหรือมอบเป็นของขวัญอันล้ำค่าในโอกาสพิเศษ เรียบหรู โดดเด่น คุณภาพพรีเมี่ยม ห้างทองเยาวราช1 ยโสธร พร้อมบริการด้วยความใส่ใจ'} />


                <div className="madeorder ptb--50">
                    <div className="section-title">
                        <h1 className="title-madeorder">ขั้นตอนการสั่ง</h1>
                    </div>

                    <img className="w-100" src="/assets/images/product/madeorder-step.webp" alt="สั่งทำแหวนทอง" />
                </div>

                <div>
                    <img className="w-100 mt--50" src="/assets/images/product/madeorderstep-ring.webp" alt="สั่งทำแหวนทอง" />
                    <img className="w-100 mt--30" src="/assets/images/product/madeorderstep-necklace.webp" alt="สั่งทำสร้อยคอทอง" />
                </div>

                <div className="section-title mt--60">
                    <h1 className="title-madeorder">กรอบพระ</h1>
                    <p className="description-madeorder">กรอบพระสำเร็จของทางร้านที่ใส่ได้พอดีกับพระของลูกค้า (ระยะเวลาเลี่ยมกรอบประมาณ 2 ชั่วโมงแล้วรับได้เลย)<br/>
                    กรอบพระงานสั่งทำพิเศษ แบบลงยา ยกซุ้ม ประดับเพชรแท้หรือเพชรสังเคราะห์ ประดับพลอยแท้หรือพลอยสังเคราะห์ (ระยะเวลาสั่งทำ 3-4 สัปดาห์)</p>
                </div>
                <Router>
                    <ProductMenu product_category={'MadeOrder'} />
                </Router>


                {/* ---------------------------------------- */}

                {/* Start Back To Top */}
                < div className="backto-top" >
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div >
                {/* End Back To Top */}
                <Footer />
            </React.Fragment>
        )
    }
}
export default MadeOrder;