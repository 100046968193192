import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Accordion from "../blocks/Accordion";

class Contact extends Component {
    componentDidMount() {
        if (window.location.hash) {
            const element = document.getElementById(window.location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    handleFAQClick = (e) => {
        e.preventDefault();
        const faqElement = document.getElementById('faq');
        if (faqElement) {
            faqElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='คำถามที่พบบ่อย' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />

                <section id="faq" className="contact-area">
                    <div className="ptb--40">
                        <div className="contact-top">
                            <div className="container">
                                <div className="FAQ-section">
                                    <div className="section-title mb--30 text-left">
                                        <h3 className="title ">คำถามที่พบบ่อย</h3>
                                    </div>
                                    <Accordion />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Start Back To Top */}
                <div className="backto-top" >
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
            </React.Fragment >
        )
    }
}
export default Contact