import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import ProductMenu from "./product/ProductMenu";
import Breadcrumb from "./common/Breadcrumb";


class Product extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='ทองรูปพรรณ ' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />
                <Breadcrumb title={'ทองรูปพรรณ'} bgimage={'/assets/images/breadcrumb/breadcrumb-product.webp'} description={'กว่า 70 ปี ที่ทำธุรกิจร้านทอง เราจะเน้นการขายทองรูปพรรณและทองคำแท่งเป็นหลัก ซึ่งจุดแข็งที่ทำให้เราประสบความสำเร็จอย่างยาวนาน นอกจากการบริการดูแลลูกค้าเป็นอย่างดีแล้ว เรามุ่งเน้นลวดลายทองคำที่สวยงาม ทันสมัย โดดเด่น ไม่ซ้ำใคร ให้เข้ากับรสนิยมการบริโภคของลูกค้า ซึ่งเปลี่ยนไปแต่ละกาลเวลา  วัตถุประสงค์ของเรา คือ ส่งมอบทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ. จากเยาวราชกรุงเทพเท่านั้น และบริการลูกค้าให้ได้รับความประทับใจสูงสุด เราพร้อมอัปเดตลายทองใหม่ ทุกอาทิตย์ และรับงานสั่งทำทุกประเภท เพชร พลอย ทอง 99.99%, 96.5%, 90% และงานเลี่ยมกรอบพระสำเร็จ 90% หรือกรอบพระฝังเพชรแท้หรือเพชรรัสเซียทั้งแบบลงยาและไม่ลงยา กรอบพระยกซุ้มแบบลงยาและไม่ลงยา'} />

                <div className="product-area">
                    <div className="product-des">
                        <div className="bg_color--1 ptb--50 mb--50">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-12 col-md-12">
                                        <div className="section-1">
                                            <div className="section-title mt--20 mb--40 mt_mobile--10">
                                                <h1 className="main-title mt--20">คุณลักษณะทองรูปพรรณ</h1>
                                                <p className="description">ไม่ว่าคุณจะซื้อทองรูปพรรณวันนี้หรือวันไหน “ช่วงเวลา” ก็จะส่งผลต่อราคาการซื้อขายทองคำเพียงอย่างเดียว เว้นเสียแต่ คุณซื้อทองรูปพรรณที่มีความบริสุทธิ์ที่แตกต่างกัน ซึ่งทองรูปพรรณจะมีความบริสุทธิ์ด้วยกัน 2 แบบ คือ ความบริสุทธิ์ 99.99% ที่เป็นทองคำมาตรฐานโลก และ ความบริสุทธิ์ 96.5% ที่เป็นมาตรฐานทองไทย</p>
                                                <p className="description mt--20"><b>ในไทยจะนิยมซื้อ-ขายทองรูปพรรณที่ความบริสุทธิ์ 96.5%</b></p>
                                            </div>

                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                                    <div className="row">
                                                        <div className="section-title">
                                                            <img src="/assets/images/about/about-7.webp" alt="ทองคำแห่งประเทศไทย" />
                                                            <h2 className="tagline">คุณภาพทองคำ 96.5%</h2>
                                                            <p className="tagline">มาตรฐานจากสมาคมค้าทองคำแห่งประเทศไทย</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                                    <div className="row">
                                                        <div className="section-title">
                                                            <img src="/assets/images/about/about-6.webp" alt="ทองคำแห่งประเทศไทย" />
                                                            <h2 className="tagline">น้ำหนัก 1 บาท = 15.16 กรัม</h2>
                                                            <p className="tagline">น้ำหนักทองคํารูปพรรณมาตรฐาน</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-12 col-md-12">
                                        <div className="thumbnail mt--40">
                                            <img className="w-100" src="/assets/images/about/about-10.webp" alt="ห้างทองเยาวราช1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg_color--1">
                        <div className=" ptb--40 ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title mb--30 text-center">
                                            <h4 className="title">ทองรูปพรรณของห้างทองเยาวราช1 ยโสธร</h4>
                                            <p className="description mt--30">ห้างทองเยาวราช 1 ยโสธร รับซื้อและขายทองรูปพรรณ เรามุ่งเน้นลวดลายทองคำที่สวยงาม ทันสมัย ให้เข้ากับรสนิยมการบริโภคของลูกค้าซึ่งเปลี่ยนไปแต่ละกาลเวลา</p>
                                            <div className="shortcut-menu-area bg_color--1">
                                                <div className="portfolio-sacousel-inner mb--55">
                                                    <ProductMenu />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment >
        )
    }
}
export default Product;
