import React, { Component } from "react";

class MenuBar extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="product-area">
                    <div className="menubar">
                        <div className="col-3">
                            <table>
                                <tr >
                                    <a className="btn-default size-md" href="/gold965">ทองคำแท่ง 96.5%</a>
                                </tr>
                                <tr >
                                    <a className="btn-default size-md" href="/gold99">ทองคำแท่ง 99.99% LBMA</a>
                                </tr>
                                <tr >
                                    <a className="btn-default size-md" href="/ring">แหวน</a>
                                </tr>
                                <tr>
                                    <a className="btn-default size-md" href="/necklace">สร้อยคอ</a>
                                </tr>
                                <tr>
                                    <a className="btn-default size-md" href="/bracelet">สร้อยข้อมือ</a>
                                </tr>
                                <tr>
                                    <a className="btn-default size-md" href="/bangle">กำไลแขน</a>
                                </tr>
                                <tr>
                                    <a className="btn-default size-md" href="/pendant">จี้</a>
                                </tr>
                                <tr>
                                    <a className="btn-default size-md" href="/earring">ต่างหู</a>
                                </tr>
                                <tr>
                                    <a className="btn-default size-md" href="/children-bracelet">สร้อยคอ สร้อยแขน <br/> กำไลข้อเท้าเด็ก</a>
                                </tr>
                                <tr>
                                    <a className="btn-default size-md" href="/jewelry99">ทองรูปพรรณ 99.99%</a>
                                </tr>
                                <tr>
                                    <a className="btn-default size-md" href="/goldbar">ทองคำแท่ง</a>
                                </tr>
                                <tr>
                                    <a className="btn-default size-md" href="/made-order">งานสั่งทำ</a>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default MenuBar;
