import React, { useState, useEffect } from 'react';

import { FaArrowAltCircleUp } from "react-icons/fa";
import { FaArrowAltCircleDown } from "react-icons/fa";


const GoldAPI = () => {
    const [thaiDateTime, setThaiDateTime] = useState({ date: '', time: '' });
    const [barGoldBuyPrice, setBarGoldBuyPrice] = useState(null);
    const [barGoldSellPrice, setBarGoldSellPrice] = useState(null);
    const [barGoldBuyChange, setBarGoldBuyChange] = useState(null);

    // const [ornamentalGoldBuyPrice, setOrnamentalGoldBuyPrice] = useState(null);
    // const [ornamentalGoldSellPrice, setOrnamentalGoldSellPrice] = useState(null);
    // const [ornamentalGoldBuyChange, setOrnamentalGoldBuyChange] = useState(null);

    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const fetchGoldPrice = async () => {
            try {
                const response = await fetch('https://apicheckprice.huasengheng.com/api/values/getprice/');
                if (!response.ok) {
                    throw new Error('กำลังโหลด...');
                }
                const data = await response.json();

                const now = new Date();
                const thaiDateString = now.toLocaleDateString('th-TH', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    timeZone: 'Asia/Bangkok',
                });
                const thaiTimeString = now.toLocaleTimeString('th-TH', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    timeZone: 'Asia/Bangkok',
                    hour12: false,
                });
                setThaiDateTime({ date: thaiDateString, time: thaiTimeString });


                setBarGoldBuyPrice(data[1].Buy);
                setBarGoldSellPrice(data[1].Sell);
                setBarGoldBuyChange(data[1].BuyChange);

                // setOrnamentalGoldBuyPrice(data[2].Buy);
                // setOrnamentalGoldSellPrice(data[2].Sell);
                // setOrnamentalGoldBuyChange(data[2].BuyChange);

            } catch (error) {
                console.error('Error fetching gold price:', error);
            }
        };

        const timer = setInterval(() => {
            setTime(new Date());
        }, 1000);

        fetchGoldPrice();
        const intervalId = setInterval(fetchGoldPrice, 60000);
        return () => clearInterval(intervalId, timer);




    }, []);

    // Function to determine sign
    const getSign = (difference) => {
        return difference > 0 ? '+' : difference < 0 ? '-' : '';
    };

    const positiveChangeStyle = {
        color: 'green',
         fontSize: '32px',
        marginTop: '10px'
    };

    const positiveChangeSpanStyle = {
    };

    const negativeChangeStyle = {
        color: 'red',
        fontSize: '32px',
       marginTop: '10px'
    };

    const negativeChangeSpanStyle = {
    };

    const nochangeChangeStyle = {
        color: '#0e3c70',
        fontSize: '32px',
       marginTop: '10px'
    };

    const nochangeChangeSpanStyle = {
    };







    const timeString = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: 'Asia/Bangkok',
        hour12: false
    }).format(time);

    return (
        <div className='gold-api-area'>
            <div className="title">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-8 col-7">
                        <h1>ราคาทองวันนี้</h1>
                        <p>{thaiDateTime.date} &nbsp;&nbsp;เวลา {timeString} น.</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-5 text-right">
                        <img className="logo" src="/assets/images/logo/logo-one.webp" alt="" />
                    </div>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th colspan="3">
                            <h3 className="title-product">
                                ทองคำแท่ง 96.5%
                            </h3>
                        </th>
                    </tr>
                    <tr>
                        <th className='text-color-buy'>ซื้อ (บาท)</th>
                        <th className='text-color-sell'>ขาย (บาท)</th>
                        <th className='text-color-diff'>เปลี่ยนแปลง (บาท)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="price-table">
                        <td className="price">{barGoldBuyPrice}</td>
                        <td className="price">{barGoldSellPrice}</td>
                        {getSign(barGoldBuyChange) === '+' ? (
                            <>
                                <td>
                                    <h1 style={positiveChangeStyle}>
                                        <span style={positiveChangeSpanStyle}><FaArrowAltCircleUp />&nbsp;&nbsp;{`${Math.abs(barGoldBuyChange)}`}</span>
                                    </h1>
                                </td>
                            </>
                        ) : getSign(barGoldBuyChange) === '-' ? (
                            <>
                                <td>
                                    <h1 style={negativeChangeStyle}>
                                        <span style={negativeChangeSpanStyle}><FaArrowAltCircleDown />&nbsp;&nbsp;{`${Math.abs(barGoldBuyChange)}`}</span>
                                    </h1>
                                </td>
                            </>
                        ) : (
                            <td>
                                <h1 style={nochangeChangeStyle}>
                                    <span className="price" style={nochangeChangeSpanStyle}>{`${Math.abs(barGoldBuyChange)}`}</span>
                                </h1>
                            </td>
                        )}
                    </tr>
                </tbody>
            </table>



        </div>
    );
};
export default GoldAPI;