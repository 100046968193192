import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaFacebook, FaLine, FaPhoneAlt, FaMapMarkedAlt } from "react-icons/fa";

import { HashLink } from 'react-router-hash-link';

const logoUrl = <img src="/assets/images/logo/logo-light.webp" alt="ห้างทองเยาวราช 1 ยโสธร" />;



class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer-area footer-style-01 bg_color--2">
                    <div className="bg_color--1 ">

                        <div className="row">
                            <div className="footer-top-left col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ptb--100" data-black-overlay="5">
                                <div className="section-title">
                                    <a href="/about" class="btn-white">เกี่ยวกับเรา</a>
                                </div>
                            </div>
                            <div className="footer-top-right col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ptb--100" data-black-overlay="5">
                                <div className="section-title">
                                    <HashLink smooth to="/faq" class="btn-white" onClick={this.handleFAQClick}> คำถามที่พบบ่อย</HashLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Footer Area  */}
                    <div className="footer-wrapper ptb--70">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-4 col-lg-12 col-md-9 col-sm-12 col-12">
                                    <div className="ft-text">
                                        <div className="logo">
                                            <Link to="/">
                                                {logoUrl}
                                            </Link>
                                        </div>
                                        <p>ห้างทองเยาวราช1 ยโสธร
                                            ทองคำเนื้อดี คุณภาพมาตรฐานตาม สคบ จากเยาวราชกรุงเทพ ลวดลายสวยประณีต โดดเด่น ทันสมัย ไม่ซ้ำใคร
                                            รับประกันราคาทั้งซื้อและขายที่ลูกค้าพอใจ ทั้งราคาปลีก และราคาส่ง</p>
                                        <p>Copyright © 2023 YAOWARAT1 CO.,LTD. <br />All rights reserved.</p>
                                    </div>
                                </div>

                                {/* Start Single Widget  */}
                                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-12 mt_sm--20">
                                    <div className="footer-link">
                                        <h4>เมนู</h4>
                                        <ul className="ft-link">
                                            <li><Link to="/">หน้าแรก</Link></li>
                                            <li><Link to="/product">ทองรูปพรรณ</Link></li>
                                            <li><Link to="/gold965">ทองคำแท่ง 96.5%</Link></li>
                                            <li><Link to="/gold99">ทองคำแท่ง 99.99% LBMA</Link></li>
                                            <li><Link to="/goldsaver">ออมทอง</Link></li>
                                            <li><Link to="/about">เกี่ยวกับเรา</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Widget  */}


                                {/* Start Single Widget  */}
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 mt_sm--20">
                                    <div className="footer-link">
                                        <h4>ห้างทองเยาวราช 1 <br /> ยโสธร</h4>
                                        <ul className="ft-link">
                                            <li><a href="tel:+6645 712 781" class="button mt--10"><FaPhoneAlt className="icon" />โทร 045 712 693</a></li>
                                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/yaowarat1yasothon" class="button mt--15"><FaFacebook className="icon" />Facebook</a></li>
                                            <li><a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2XBIm9d" class="button mt--15"><FaLine className="icon" />Line</a></li>
                                            <li><a target="_blank" rel="noopener noreferrer" href="https://maps.app.goo.gl/zyuPWneQguKBZm9v5" class="button mt--15"><FaMapMarkedAlt className="icon" />แผนที่ Google Map</a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Widget  */}

                                {/* Start Single Widget  */}
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 mt_sm--20">
                                    <div className="footer-link">
                                        <h4>ห้างทองเยาวราชยโสธร สาขาตลาดเก่า</h4>
                                        <ul className="ft-link">
                                            <li><a href="tel:+6645 712 781" class="button mt--10"><FaPhoneAlt className="icon" />โทร 045 712 781</a></li>
                                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Yaowaradyaso" class="button mt--15"><FaFacebook className="icon" />Facebook</a></li>
                                            <li><a target="_blank" rel="noopener noreferrer" href="https://line.me/R/ti/p/@464byixt" class="button mt--15"><FaLine className="icon" />Line</a></li>
                                            <li><a target="_blank" rel="noopener noreferrer" href="https://maps.app.goo.gl/nDprwmhmmJT6mnie6" class="button mt--15"><FaMapMarkedAlt className="icon" />แผนที่ Google Map</a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Widget  */}

                                {/* Start Single Widget  */}
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 mt_sm--20">
                                    <div className="footer-link">
                                        <h4>ห้างทองทองแวววาว <br />สาขาตลาดใหม่</h4>
                                        <ul className="ft-link">
                                            <li><a href="tel:+6645 712 103" class="button mt--10"><FaPhoneAlt className="icon" />โทร 045 712 103</a></li>
                                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/winnerygold" class="button mt--15"><FaFacebook className="icon" />Facebook</a></li>
                                            <li><a target="_blank" rel="noopener noreferrer" href="https://line.me/R/ti/p/~sharkie2525" class="button mt--15"><FaLine className="icon" />Line</a></li>
                                            <li><a target="_blank" rel="noopener noreferrer" href="https://maps.app.goo.gl/bEBcpXN4q1uPYhyr9" class="button mt--15"><FaMapMarkedAlt className="icon" />แผนที่ Google Map</a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Widget  */}
                            </div>
                        </div>
                    </div>
                    {/* End Footer Area  */}
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;