import React, { Component } from "react";
// import { FaInstagram, FaFacebookF, FaLine } from "react-icons/fa";
// import { SiTiktok } from "react-icons/si";
import { FiX, FiMenu } from "react-icons/fi";
import { Link } from 'react-router-dom';

class Header extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })

    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader() { }
    render() {
        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 300) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });
        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { color = 'default-color' } = this.props;
        let logoUrl = <img src="/assets/images/logo/logo.webp" alt="ห้างทองเยาวราช 1 ยโสธร" />;


        return (
            <header className={`header-area header--fixed ${color}`}>
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                            <a href='/'>
                                {logoUrl}
                            </a>
                        </div>
                        <nav className="mainmenunav d-lg-block ml--50">
                            <ul className="mainmenu">
                                <div className="mobile-contact mb--30 mt--10">
                                    <a href='/'>
                                        <img className="w-100" src="/assets/images/logo/logo.webp" alt="ห้างทองเยาวราช 1 ยโสธร" />
                                    </a>
                                </div>
                                <li className="has-droupdown"><Link to="/" >หน้าแรก</Link></li>
                                <li className="has-droupdown"><Link to="/product" >ทองรูปพรรณ ▾</Link>
                                    <ul className="submenu">
                                        <li><Link to="/ring">แหวน</Link></li>
                                        <li><Link to="/necklace">สร้อยคอ</Link></li>
                                        <li><Link to="/bracelet">สร้อยข้อมือ</Link></li>
                                        <li><Link to="/bangle">กำไลแขน</Link></li>
                                        <li><Link to="/pendant">จี้</Link></li>
                                        <li><Link to="/earring">ต่างหู</Link></li>
                                        <li><Link to="/children-necklace">สร้อยคอ สร้อยแขน <br /> กำไลข้อเท้าเด็ก</Link></li>
                                        <li><Link to="/jewelry99">ทองรูปพรรณ 99.99%</Link></li>
                                        <li><Link to="/made-order">งานสั่งทำ</Link></li>
                                    </ul>
                                </li>
                                <li className="has-droupdown"><a href="#ทองคำแท่ง">ทองคำแท่ง ▾</a>
                                    <ul className="submenu">
                                        <li><Link to="/gold965">ทองคำแท่ง 96.5%</Link></li>
                                        <li><Link to="/gold99">ทองคำแท่ง 99.99% LBMA</Link></li>
                                    </ul>
                                </li>
                                <li className="has-droupdown"><Link to="/goldsaver" >ออมทอง</Link>
                                </li>
                                <li className="has-droupdown"><Link to="/about" >เกี่ยวกับเรา</Link>
                                </li>
                                <li className="has-droupdown"><Link to="/contact" >ติดต่อเรา</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="header-right">
                        <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;